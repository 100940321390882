import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const IESStageGauage = observer(({ setting }) => {
  const { t } = useTranslation();
  var option = {
    series: [
      {
        type: 'gauge',
        axisLine: {
          lineStyle: {
            width: 30,
            color: [
              [0.9, '#55cfe4'],
              [1, '#74a5f7']
            ]
          }
        },
        pointer: {
          itemStyle: {
            color: 'auto'
          }
        },
        axisTick: {
          distance: -30,
          length: 8,
          lineStyle: {
            color: '#fff',
            width: 2
          }
        },
        splitLine: {
          distance: -30,
          length: 30,
          lineStyle: {
            color: '#fff',
            width: 4
          }
        },
        axisLabel: {
          color: 'auto',
          distance: 35,
          fontSize: 12
        },
        detail: {
          valueAnimation: true,
          formatter: function (value) {
            return value + '%';
          },
          color: 'auto',
          fontSize: 15,
          offsetCenter: [0, "60%"],
        },
        radius: '75%',
        data: [
          {
            value: setting.value
          }
        ]
      }
    ]
  }

  return (
    <>
      <ReactEcharts
        style={{
          height: setting.height,
          width: setting.width,
          margin: '0 auto',
        }}
        option={option}
      />
    </>
  );
});

export default IESStageGauage;