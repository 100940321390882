import { observer } from "mobx-react"
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AcademicExternalNaplan from "./academic-external-naplan";
import useStores from "../../hooks/use-stores";
import AcademicPAT from "./academic-PAT";
import TabPanel from "../tab-panel";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function a11yProps(index: any, isActive) {
  return {
    id: `external-tab-${index}`,
    'aria-controls': `external-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const AcademicExternal = observer(({ stdUid }) => {
  const { studentStore } = useStores();
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    studentStore.getStudentPATOverview(stdUid);
    studentStore.getStudentPATDetail(stdUid);
  }, [stdUid]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="mt-4 border rounded-xl shadow-lg">
      <div className="px-2">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="external tabs example"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab label="NAPLAN" {...a11yProps(0, 0 == value)} />
          <Tab label="PAT" {...a11yProps(1, 1 === value)} />
          {/* <Tab label="VCE" {...a11yProps(2, 2 === value)} />
          <Tab label="ACER" {...a11yProps(3, 3 === value)} /> */}
        </Tabs>
      </div>
      <TabPanel value={value} paddingValue={0} index={0}>
        <AcademicExternalNaplan stdUid={stdUid} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AcademicPAT />
      </TabPanel>
      <TabPanel value={value} index={2}>
      </TabPanel>
      <TabPanel value={value} index={3}>
      </TabPanel>
    </div>
  )
})

export default AcademicExternal;