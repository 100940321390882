import React, { useState, useEffect, useRef } from "react";
import { Button, CircularProgress, MenuItem, Select, Tab, Tabs } from "@material-ui/core";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import ShowChartTwoToneIcon from "@material-ui/icons/ShowChartTwoTone";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WellbeingTab from "../../components/student-components/well-being-tab";
import SocialBehaviorTab from "../../components/student-components/social-behavior-tab";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import AcademicExternal from "../../components/student-components/academic-external";
import AcademicOverview from "../../components/student-components/overview";
import TabPanel from "../../components/tab-panel";
import { observer } from "mobx-react";
import AcademicAtSchoolTab from "../../components/student-components/academic-at-school";
import { navigate } from "gatsby-link";
import LoadingPanel from "../../components/loading-panel";
import { toJS } from "mobx";
import SlideDown from "react-slidedown";
import { TAG_STYLE } from "../../constants/style";
import { useTranslation } from 'react-i18next';
import DashboardIcon from '@material-ui/icons/Dashboard';
import OverviewTab from "../../components/student-components/overview";
import { getPermissionValidity, PermissionCategory } from "../../constants/options";

function ParentTabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

function ChildTabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const StudentDetailFilter = observer(({ callback, semesterId }) => {
  const { filterStore } = useStores();
  const { t } = useTranslation();
  const [year, setYear] = useState<string>("");
  const [semester, setSemester] = useState(0);
  const [semesterList, setSemesterList] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const DEFAULT_YEAR = new Date().getFullYear().toString();

  const onChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as string);
    setSemester(0);
    setSemesterList([]);
    filterStore.getSemesterBasedOnYear(event.target.value as string);
    if (filterStore.semesterList.length > 0) {
      var found = false;
      for (var sem of filterStore.semesterList) {
        setSemester(sem.id);
        setSemesterList([sem.id])
        var selectedSemester = filterStore.semesterList.find(e => e.id == sem.id);
        var startTerm = toJS(selectedSemester).academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = toJS(selectedSemester).academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
        found = true;
        break;
      }
      if (!found) {
        setSemester(0);
        setSemesterList([]);
      }
    }
  }

  useEffect(() => {
    // filterStore.getSemesterDateList().then(() => {
    // setYear(DEFAULT_YEAR);
    if (filterStore.academicYearList.length > 0) {
      // for (var ay of filterStore.academicYearList) {
      //   if (ay.yearDescription == DEFAULT_YEAR) {
      //     setYear(ay.yearDescription);
      //     filterStore.getSemesterBasedOnYear(ay.yearDescription);
      //     break;
      //   }
      // }
      // filterStore.getCurrentSemester();
      // setSemester(filterStore.currentSemester);
      // setSemesterList([filterStore.currentSemester]);
      // var selectedSemester = filterStore.semesterList.find(e => e.id == filterStore.currentSemester);
      // if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
      //   var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
      //   var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
      //   setStartDate(startTerm.startDate);
      //   setEndDate(endTerm.endDate);
      // }
      let fromDate: string = "";
      let toDate: string = "";
      for (var year of filterStore.academicYearList) {
        if (year.academicSemesters.find(s => s.id == semesterId[0])) {
          setYear(year.yearDescription);
          let semesterObj = year.academicSemesters.find(s => s.id == semesterId[0]);
          fromDate = semesterObj.academicTerms[0].academicTermDates.find((data) => data.yearLevelId > 0).startDate;
          if (semesterId.length > 1) {
            let semesterObj2 = year.academicSemesters.find(s => s.id == semesterId[1]);
            toDate = semesterObj2.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
          } else {
            toDate = semesterObj.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
          }
          if (semesterId.length > 1) setSemester(0)
          else setSemester(semesterId[0]);
          setStartDate(fromDate);
          setEndDate(toDate);
          setSemesterList([filterStore.currentSemester])
          break;
        }
      }
    }

    // });
  }, [semesterId]);

  const onChangeSemster = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSemester(event.target.value as number);
    setSemesterList([event.target.value as number]);

    if (event.target.value != 0) {
      var selectedSemester = filterStore.semesterList.find(e => e.id == (event.target.value as number));
      if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
        var startTerm = toJS(selectedSemester).academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = toJS(selectedSemester).academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
      }
    } else {
      if (filterStore.semesterList.length > 0) {
        let semList = [filterStore.semesterList[0].id, filterStore.semesterList[1].id];
        setSemesterList(semList);

        // ===== First Sem ===== //
        var firstSem = filterStore.semesterList.find(e => e.id == semList[0]);
        if (firstSem.academicTerms[0].academicTermDates.length > 0) {
          var startTerm = firstSem.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
          setStartDate(startTerm.startDate);
        }
        // ===== Sec Sem ===== //
        var secSem = filterStore.semesterList.find(e => e.id == semList[1]);
        if (secSem.academicTerms[0].academicTermDates.length > 0) {
          var endTerm = secSem.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
          setEndDate(endTerm.endDate);
        }
      }
    }
  }

  return (
    <div className="mt-4 shadow-lg rounded-xl border">
      <div className="grid grid-cols-5 gap-8 p-4">
        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-4 mt-1">
            {t('YEAR')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-full px-2"
            style={{ height: 30 }}
            value={year}
            onChange={onChangeYear}
          >
            {
              filterStore.academicYearList &&
              filterStore.academicYearList.map((ay, index) => (
                <MenuItem key={`academic_year_list_${ay.id}_${index}`} value={ay.yearDescription}>
                  {ay.yearDescription}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-4 mt-1">
            {t('SEMESTER')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-full px-2"
            style={{ height: 30 }}
            value={semester}
            onChange={onChangeSemster}
            disabled={year == ""}
          >
            <MenuItem value={0}>{t('ALL')}</MenuItem>
            {
              filterStore.semesterList.length > 0 &&
              filterStore.semesterList.map((sem, index) => (
                <MenuItem key={`semester_list_${sem.id}_${index}`} value={sem.id}>
                  {sem.semesterDescription.substring(8, 10)}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="w-full text-right">
          <Button
            className="bg-buttonPrimary text-white text-sm"
            onClick={() => {
              callback({
                year,
                semesterList,
                startDate,
                endDate
              })
            }}
          >
            {t('SEARCH')}
          </Button>
        </div>
      </div>
    </div>
  );
});

const StudentDetailPage = observer(({ location }) => {
  const { studentStore, filterStore } = useStores();
  const { t } = useTranslation();
  const contentAreaStyle = "mt-4 border rounded-xl shadow-lg"
  const [studentInfo, setStudentInfo] = useState<any>();
  const [studentList, setStudentList] = useState<any>([]);
  const [parentTab, setParentTab] = useState<number>(0);
  const [childTab, setChildTab] = useState<number>(0);
  const [selectedSubject, setSelectedSubject] = useState<string>("");
  const [showSlideDown, setShowSlideDown] = useState<boolean>(false);
  const [showTabSlideDown, setShowTabSlideDown] = useState<boolean>(true);
  const [targetPosition, setTargetPosition] = useState<number>(0);
  const [confirmApiSent, setConfirmApiSent] = useState<any>({
    atSchool: false,
    attendance: false,
    behavior: false,
  });
  const [dateDesc, setDateDesc] = useState<any>({
    atSchool: new Date().getFullYear().toString(),
    attendance: new Date().getFullYear().toString(),
    behavior: new Date().getFullYear().toString(),
  });
  const fieldStyle = "text-gray-500 ml-2";
  const childTabStyle = `rounded-b-xl shadow-lg ${showTabSlideDown && 'border'}`;

  useEffect(() => {
    setStudentInfo(null);
    setSelectedSubject("");
    console.log("STUDENT", location.state);

    if (!location.state.student || !location.state.semester) {
      navigate("/student-overview");
    } else {
      // Use this id as condition in use for filter search student short list
      studentStore.currentStudentId = location.state.student;
      studentStore.searchStudent({
        searchString: location.state.student,
        semesterIdList: location.state.semester ? location.state.semester : [filterStore.currentSemester]
      }).then((response) => {
        setStudentInfo(response[0]);
        setConfirmApiSent({
          atSchool: false,
          attendance: false,
          behavior: false,
        })
        var selectedSemester = [];
        filterStore.getSemesterBasedOnYear(response[0].academicYear.toString());
        if (location.state.semester.length == 1) {
          selectedSemester = filterStore.semesterList.filter(e => e.id == location.state.semester[0]);
        } else if (location.state.semester.length == 2) {
          selectedSemester = filterStore.semesterList.filter(e => e.id == location.state.semester[0] || e.id == location.state.semester[1]);
        }
        if (selectedSemester.length > 0 && selectedSemester[0].academicTerms[0].academicTermDates.length > 0) {
          setDateDesc({
            atSchool: response[0].academicYear.toString() + ' ' + (selectedSemester.length == 1 ? selectedSemester[0].semesterDescription : 'All Semesters'),
            attendance: response[0].academicYear.toString() + ' ' + (selectedSemester.length == 1 ? selectedSemester[0].semesterDescription : 'All Semesters'),
            behavior: response[0].academicYear.toString() + ' ' + (selectedSemester.length == 1 ? selectedSemester[0].semesterDescription : 'All Semesters'),
          });
          var startTerm = selectedSemester[0].academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
          var endTerm = selectedSemester.length == 1 ?
            selectedSemester[0].academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0)
            : selectedSemester[1].academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
          var req = {
            uid: location.state.student,
            semesterIdList: location.state.semester,
            fromDate: startTerm.startDate,
            toDate: endTerm.endDate
          }
          console.log("ATSCHOOL REQ", req);
          if (parentTab == 1 && childTab == 0) {
            studentStore.getStudentAcadamicAtSchool(req);
            confirmApiSent.atSchool = true;
          }
          if (parentTab == 2 && childTab == 0) {
            studentStore.getStudentAttendance(req);
            confirmApiSent.attendance = true;
          }

          if (parentTab == 3 && childTab == 0) {
            studentStore.getStudentBehavior(req);
            confirmApiSent.behavior = true;
          }
        }

      });

      if (!location.state.studentList || location.state.studentList.length == 0) {
        console.log("short list", toJS(filterStore.homeRoomCodeList.map(h => h.homeroomCode)));

        studentStore.filterStudentShortList({
          semesterIdList: location.state.semester,
          homeRoomCode: filterStore.homeRoomCodeList.map(h => h.homeroomCode),
          subjectClassIdList: []
        }).then((response) => {
          setStudentList(response);
        })
      } else setStudentList(location.state.studentList);
    }
  }, [location.state ? location.state.student : null]);

  const onChangeParentTab = (event, newValue) => {
    setParentTab(newValue);
    setChildTab(0);

    if (newValue == 2 || newValue == 3) {
      console.log("semester in", newValue);

      let date = getStartEndDate(location.state.semester);
      var req = {
        uid: location.state.student,
        semesterIdList: location.state.semester,
        fromDate: date ? date[0] : "",
        toDate: date ? date[1] : ""
      }
      if (newValue == 2 && !confirmApiSent.attendance) {
        studentStore.getStudentAttendance(req);
        confirmApiSent.attendance = true;
      }
      if (newValue == 3 && !confirmApiSent.behavior) {
        studentStore.getStudentBehavior(req);
        confirmApiSent.behavior = true;
      }
    }
    setSelectedSubject("");
  }

  const onChangeChildTab = (event, newValue) => {
    setChildTab(newValue);
    let date = getStartEndDate(location.state.semester);

    var req = {
      uid: location.state.student,
      semesterIdList: location.state.semester,
      fromDate: date ? date[0] : "",
      toDate: date ? date[1] : "",
    }
    console.log(req);

    if (parentTab == 1 && newValue == 0 && !confirmApiSent.atSchool) {
      // studentStore.getStudentAcadamicAtSchool(req);
      confirmApiSent.atSchool = true;
    }
    if (parentTab == 2 && newValue == 0 && !confirmApiSent.attendance) {
      studentStore.getStudentAttendance(req);
      confirmApiSent.attendance = true;
    }

    if (parentTab == 3 && newValue == 0 && !confirmApiSent.behavior) {
      studentStore.getStudentBehavior(req);
      confirmApiSent.behavior = true;
    }
    setSelectedSubject("");
  }

  const onApplyFilter = (dataInfo: any,) => {
    console.log(dataInfo);
    var req = {
      uid: location.state.student,
      semesterIdList: dataInfo.semesterList ? dataInfo.semesterList : [0],
      fromDate: dataInfo.startDate,
      toDate: dataInfo.endDate,
    }

    if (parentTab == 1 && childTab == 0) {
      // studentStore.getStudentAcadamicAtSchool(req);
      if (dataInfo.semesterList.length > 1) {
        setDateDesc({
          atSchool: dataInfo.year + ' All Semesters',
          attendance: dateDesc.attendance,
          behavior: dateDesc.behavior
        })
        // dateDesc.atSchool = dataInfo.year + ' All Semesters';
      } else {
        var selectedSemester = filterStore.semesterList.find(e => e.id == dataInfo.semesterList[0]);
        if (selectedSemester) {
          setDateDesc({
            atSchool: dataInfo.year + ' ' + selectedSemester.semesterDescription,
            attendance: dateDesc.attendance,
            behavior: dateDesc.behavior
          })
        }
      }
    }
    console.log("CURRENT", toJS(dateDesc.atSchool));

    if (parentTab == 2 && childTab == 0) {
      studentStore.getStudentAttendance(req);
      if (dataInfo.semesterList.length > 1) {
        setDateDesc({
          atSchool: dateDesc.atSchool,
          attendance: dataInfo.year + ' All Semesters',
          behavior: dateDesc.behavior
        })
      } else {
        var selectedSemester = filterStore.semesterList.find(e => e.id == dataInfo.semesterList[0]);
        if (selectedSemester) {
          setDateDesc({
            atSchool: dateDesc.atSchool,
            attendance: dataInfo.year + ' ' + selectedSemester.semesterDescription,
            behavior: dateDesc.behavior
          })
        }
      }
    }

    if (parentTab == 3 && childTab == 0) {
      studentStore.getStudentBehavior(req);
      if (dataInfo.semesterList.length > 1) {
        setDateDesc({
          atSchool: dateDesc.atSchool,
          attendance: dateDesc.attendance,
          behavior: dataInfo.year + ' All Semesters'
        })
      } else {
        var selectedSemester = filterStore.semesterList.find(e => e.id == dataInfo.semesterList[0]);
        if (selectedSemester) {
          setDateDesc({
            atSchool: dateDesc.atSchool,
            attendance: dateDesc.attendance,
            behavior: dataInfo.year + ' ' + selectedSemester.semesterDescription
          })
        }
      }
    }
  }

  const onApplyStudentFilter = (req) => {
    var tempSubjectClassIdList = [];

    if (req.stdSubject !== 0) {
      if (req.stdSubjectClass !== 0) tempSubjectClassIdList.push(req.stdSubjectClass);
      else
        filterStore.subjectClassList.map(e => { tempSubjectClassIdList.push(e.id) });
    }

    var finalReq: any = {
      semesterIdList: req.stdListSemesterIdList,
      homeRoomCode: req.stdFullListClassCode,
      subjectClassIdList: tempSubjectClassIdList
    };

    if (typeof window !== "undefined" && JSON.parse(localStorage.PERMISSIONS).Student) {
      if (localStorage.USER_INFO && JSON.parse(localStorage.PERMISSIONS).Student.includes("Limited Student Only"))
        finalReq.teacherUid = JSON.parse(localStorage.USER_INFO).uid;
    }
    console.log(finalReq);
    if (req.domainIdList.length == 1) finalReq.domainId = req.domainIdList[0];

    studentStore.filterStudentShortList(finalReq)
      .then((response) => {
        setStudentList(response);
      })
  }

  const onActiveAtSchoolBySubject = (subject: any) => {
    setSelectedSubject(subject);
    setParentTab(1);
    setChildTab(0);

    window.scroll({
      top: targetPosition == 0 ? 1120 : targetPosition,
      left: 0,
      behavior: 'smooth'
    });
  }

  const getTargetPosition = (target: any) => {
    if (target.current)
      setTargetPosition(target.current.offsetTop);
    else setTargetPosition(1120);
  }

  const getStartEndDate = (semester) => {
    var selectedSemester = [];
    filterStore.getSemesterBasedOnYear(studentInfo.academicYear.toString());
    if (semester.length == 1) {
      selectedSemester = filterStore.semesterList.filter(e => e.id == semester[0]);
    } else if (semester.length == 2) {
      selectedSemester = filterStore.semesterList.filter(e => e.id == semester[0] || e.id == semester[1]);
    }
    if (selectedSemester.length > 0 && selectedSemester[0].academicTerms[0].academicTermDates.length > 0) {
      var startTerm = selectedSemester[0].academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
      var endTerm = selectedSemester.length == 1 ?
        selectedSemester[0].academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0)
        : selectedSemester[1].academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);

      return [startTerm.startDate, endTerm.endDate];
    }
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
          location.state ?
          <Layout
            showYearSemTerm={false}
            showFilter={false}
            showClassCode={false}
            showYearRange={false}
            showDomain={false}
            showDateRange={true}
            onApplyFilter={onApplyFilter}
            onApplyStudentFilter={onApplyStudentFilter}
            staffList={studentList}
            listLabel={t('STUDENT_LIST')}
          >
            <title>{t('STUDENT_PROFILE')}</title>
            <div className="col-span-4 flex flex-col m-2">
              {
                !studentInfo && studentStore.loading ? <LoadingPanel />
                  :
                  <div>
                    {
                      studentInfo && studentStore.studentIndividualRecord.length > 0 &&
                      <>
                        <div className="h-40 flex flex-row rounded-t-xl border border-b-0">
                          <div className="w-48 p-2">
                            <img
                              className="cursor-pointer h-full w-full object-cover rounded-full border"
                              src={studentInfo.image}
                              alt="studentImg"
                            />
                          </div>

                          <div className="w-full grid grid-cols-3">
                            <div className={`${fieldStyle} col-span-3 mt-4 text-barPrimary font-bold`} style={{ height: 'fit-content' }}>
                              {studentInfo.academicYear + ' ' + studentInfo.semesterDescription}
                            </div>
                            <div className={`${fieldStyle}`}>
                              {t('STUDENT')} ID:  <span className={`ml-2`}>{studentInfo.uid}</span>
                            </div>
                            <div className={`${fieldStyle}`}>
                              {t('YEAR')}: <span className={`ml-2`}>{studentInfo.yearLevel}</span>
                            </div>
                            <div className={`${fieldStyle}`}>
                              {t('HOMEROOM_CODE')}: <span className={`ml-2`}>{studentInfo.homeRoomCode}</span>
                            </div>
                            <div className={`${fieldStyle}`}>
                              {t('FIRST_NAME')}: <span className={`ml-2`}>{studentInfo.firstName}</span>
                            </div>
                            <div className={`${fieldStyle}`}>
                              {t('SURNAME')}: <span className={`ml-2`}>{studentInfo.surname}</span>
                            </div>
                            <div className={`${fieldStyle} col-span-3`}>
                              {t('MENTOR')}:
                              <span className={`ml-2`}>
                                {studentInfo.mentorName.map((item, index) => <span key={`mentor_${index}`}>{(index ? ', ' : '') + item}</span>)}
                              </span>
                            </div>
                          </div>

                        </div>
                        <div className="border rounded-b-xl shadow-lg text-gray-400 mb-4 p-4 pt-2">
                          <div className="flex justify-between">
                            <div className="text-barPrimary font-bold">{t('SUBJECTS')}:</div>
                            <button
                              type="button"
                              onClick={() => setShowSlideDown(!showSlideDown)}>
                              <KeyboardArrowDownIcon />
                            </button>
                          </div>

                          <SlideDown
                            className={'pure-menu pure-menu-scrollable '}
                            closed={!showSlideDown}
                          >
                            <div className="grid grid-cols-7 gap-2 text-sm xl:text-md mr-4 mt-4">
                              {
                                studentInfo.classCode && studentInfo.classCode.sort().map((classCode, index) => (
                                  <button
                                    type="button"
                                    key={`student_detail_subject_${index}`}
                                    className={`${TAG_STYLE} hover:bg-barPrimary hover:text-white`}
                                  // onClick={() => onActiveAtSchoolBySubject(classCode)}
                                  >
                                    {classCode}
                                  </button>
                                ))
                              }
                            </div>
                          </SlideDown>
                        </div>
                      </>
                    }
                    <>
                      {/* Tab Area */}
                      <div className={`border ${showTabSlideDown ? 'border-b-0 rounded-t-xl' : 'shadow-lg rounded-xl'} mt-4`}>
                        <Tabs
                          value={parentTab}
                          onChange={onChangeParentTab}
                          TabIndicatorProps={{ style: { display: "none" } }}
                        >
                          {getPermissionValidity(PermissionCategory.STUDENT, "StudentDetail", "Overview") &&
                            <Tab
                              label={t('OVERVIEW')}
                              icon={<DashboardIcon />}
                              style={{ minWidth: 120 }}
                              {...ParentTabProps(0, 0 === parentTab)}
                            />}

                          {getPermissionValidity(PermissionCategory.STUDENT, "StudentDetail") &&
                            <Tab
                              label={t('ACADEMICS')}
                              icon={<PersonOutlineIcon />}
                              {...ParentTabProps(1, 1 === parentTab)}
                              style={{ minWidth: 120 }}
                            />
                          }
                          {getPermissionValidity(PermissionCategory.STUDENT, "StudentDetail", "Wellbeing") &&
                            <Tab
                              label={t('WELLBEING')}
                              icon={<FavoriteBorderIcon />}
                              {...ParentTabProps(2, 2 === parentTab)}
                              style={{ minWidth: 120 }}
                            />
                          }
                          {getPermissionValidity(PermissionCategory.STUDENT, "StudentDetail", "Behavior") &&
                            <Tab
                              label={t('BEHAVIOR')}
                              icon={<RemoveRedEyeOutlinedIcon />}
                              {...ParentTabProps(3, 3 === parentTab)}
                              style={{ minWidth: 120 }}
                            />
                          }
                          {/*
                            getPermissionValidity(PermissionCategory.STUDENT, "StudentDetail", "Learning") &&
                            <Tab
                              label={t('LEARNING')}
                              icon={<ShowChartTwoToneIcon />}
                              {...ParentTabProps(4, 4 === parentTab)}
                              style={{ minWidth: 120 }}
                            />
                        */}
                          <button
                            type="button"
                            className="w-full text-right mr-4 text-gray-400"
                            onClick={() => setShowTabSlideDown(!showTabSlideDown)}>
                            <KeyboardArrowDownIcon />
                          </button>

                        </Tabs>
                      </div>

                      <TabPanel value={parentTab} index={0}>
                        <OverviewTab
                          student={location.state.student}
                          onActiveSubject={onActiveAtSchoolBySubject}
                          semesterId={location.state.semester}
                        />
                      </TabPanel>

                      <TabPanel value={parentTab} index={1}>
                        <SlideDown
                          className={'pure-menu pure-menu-scrollable '}
                          closed={!showTabSlideDown}
                        >
                          <Tabs
                            value={childTab}
                            onChange={onChangeChildTab}
                            TabIndicatorProps={{ style: { display: "none" } }}
                            className="border rounded-b-xl shadow-lg"
                          >
                            {getPermissionValidity(PermissionCategory.STUDENT, "StudentDetail", "AcademicAtSchool") &&
                              <Tab label={t('AT_SCHOOL')} {...ChildTabProps(0, 0 === childTab)} style={{ minWidth: 120 }} />}
                            {getPermissionValidity(PermissionCategory.STUDENT, "StudentDetail", "AcademicExternal") &&
                              <Tab label={t('EXTERNAL')} {...ChildTabProps(1, 1 === childTab)} style={{ minWidth: 120 }} />
                            }
                          </Tabs>
                        </SlideDown>

                        <TabPanel value={childTab} index={0}>
                          <AcademicAtSchoolTab
                            location={location}
                            subject={selectedSubject}
                            target={getTargetPosition}
                            date={dateDesc.atSchool}
                          />
                        </TabPanel>
                        <TabPanel value={childTab} index={1}>
                          <AcademicExternal stdUid={location.state.student} />
                        </TabPanel>
                      </TabPanel>

                      <TabPanel value={parentTab} index={2}>
                        <div className={childTabStyle}>
                          <SlideDown
                            className={'pure-menu pure-menu-scrollable '}
                            closed={!showTabSlideDown}
                          >
                            <Tabs
                              value={childTab}
                              onChange={onChangeChildTab}
                              TabIndicatorProps={{ style: { display: "none" } }}
                            >
                              <Tab label={t('ATTENDANCE')} {...ChildTabProps(0, 0 === childTab)} style={{ minWidth: 120 }} />
                              {/* <Tab label="Pulse" {...ChildTabProps(1, 1 === childTab)} style={{ minWidth: 120 }} /> */}
                            </Tabs>
                          </SlideDown>
                        </div>

                        {childTab == 0 && <StudentDetailFilter callback={onApplyFilter} semesterId={location.state.semester} />}
                        <div style={{ minHeight: "100vh" }}>
                          <TabPanel value={childTab} index={0}>
                            <WellbeingTab date={dateDesc.attendance} />
                          </TabPanel>

                          <TabPanel value={childTab} index={1}>
                          </TabPanel>
                        </div>
                      </TabPanel>

                      <TabPanel value={parentTab} index={3}>
                        <div className={childTabStyle}>
                          <SlideDown
                            className={'pure-menu pure-menu-scrollable '}
                            closed={!showTabSlideDown}
                          >
                            <Tabs
                              value={childTab}
                              onChange={onChangeChildTab}
                              TabIndicatorProps={{ style: { display: "none" } }}
                            >
                              <Tab label={t('SOCIAL_BEHAVIOR')} {...ChildTabProps(0, 0 === childTab)} style={{ minWidth: 120 }} />
                            </Tabs>
                          </SlideDown>
                        </div>

                        {childTab == 0 && <StudentDetailFilter callback={onApplyFilter} semesterId={location.state.semester} />}

                        <div style={{ minHeight: "100vh" }}>
                          <TabPanel value={childTab} index={0}>
                            <SocialBehaviorTab location={location} date={dateDesc.behavior} />
                          </TabPanel>
                        </div>

                      </TabPanel>

                     {/* 
                      <TabPanel value={parentTab} index={4}>
                        <div className={childTabStyle} style={{ borderTopColor: 'text-barPrimary' }}>
                          <SlideDown
                            className={'pure-menu pure-menu-scrollable '}
                            closed={!showTabSlideDown}
                          >
                            <Tabs
                              value={childTab}
                              onChange={onChangeChildTab}
                              TabIndicatorProps={{ style: { display: "none" } }}
                            >
                              <Tab label="NCCD" {...ChildTabProps(0, 0 === childTab)} style={{ minWidth: 120 }} />
                              <Tab label="PLP" {...ChildTabProps(1, 1 === childTab)} style={{ minWidth: 120 }} />
                              <Tab label={t('ENROLMENT')} {...ChildTabProps(2, 2 === childTab)} style={{ minWidth: 120 }} />
                              <Tab label={t('TIMETABLE')} {...ChildTabProps(3, 3 === childTab)} style={{ minWidth: 120 }} />
                              <Tab label={t('ACCELERATING')} {...ChildTabProps(4, 4 === childTab)} style={{ minWidth: 120 }} />
                              <Tab label={t('ADJUSTMENT')} {...ChildTabProps(5, 5 === childTab)} style={{ minWidth: 120 }} />
                            </Tabs>
                          </SlideDown>
                        </div>

                        <StudentDetailFilter callback={onApplyFilter} semesterId={location.state.semester} />

                        <div className={contentAreaStyle} style={{ minHeight: "100vh" }}>
                          <TabPanel value={childTab} index={0}>
                          </TabPanel>
                        </div>

                      </TabPanel>
                      */}
                    </>
                  </div>
              }
            </div>
          </Layout>
          : <div></div>
      }
    </>
  )
});

export default StudentDetailPage;

