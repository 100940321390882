import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BAR_RADIUS } from '../../constants/style';

const IESStackedBarOverviewChart = observer(({ setting, onEvents = null }) => {
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      formatter: function (value) {
        // console.log(value);
        if (value.length > 0) {
          return `<b>${value[0].axisValue}</b><br/>
              <table>
                <tr><td><b>Class Work</b></td><td><b>Assessment</b></td></tr>
                <tr><td>${value[0].marker} ${value[0].seriesName}: ${+value[0].data.value >= 0 ? value[0].data.value : value[0].data.value.value.value.value.value.value}</td><td>${value[4].marker} ${value[4].seriesName}: ${+value[4].data.value >= 0 ? value[4].data.value : value[4].data.value.value}</td></tr>
                <tr><td>${value[1].marker} ${value[1].seriesName}: ${+value[1].data.value >= 0 ? value[1].data.value : value[1].data.value.value}</td><td>${value[5].marker} ${value[5].seriesName}: ${+value[5].data.value >= 0 ? value[5].data.value : value[5].data.value.value}</td></tr>
                <tr><td>${value[2].marker} ${value[2].seriesName}: ${+value[2].data.value >= 0 ? value[2].data.value : value[2].data.value.value}</td><td>${value[6].marker} ${value[6].seriesName}: ${+value[6].data.value >= 0 ? value[6].data.value : value[6].data.value.value}</td></tr>
                <tr><td>${value[3].marker} ${value[3].seriesName}: ${+value[3].data.value >= 0 ? value[3].data.value : value[3].data.value.value}</td><td>${value[7].marker} ${value[7].seriesName}: ${+value[7].data.value >= 0 ? value[7].data.value : value[7].data.value.value}</td></tr>
              </table>
          `;
        }
      }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: setting.xAxis,
      axisLabel: {
        margin: 6,
        fontSize: 10,
        rotate: 50
      }
    },
    yAxis: {
      type: 'value'
    },
    series: setting.series,

  }

  const addBarBorderRadius = (option) => {
    let target = option.series;
    if (target.length > 0) {
      for (var i = 0; i < target[3].data.length; i++) {
        if (target[3].data[i].value) {
          if (target[2].data[i].value) {
            if (target[1].data[i].value) {
              target[0].data[i] = {
                value: target[0].data[i],
                // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
              };
            } else {
              target[1].data[i] = {
                value: target[1].data[i],
                // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
              };
            }
          } else {
            target[2].data[i] = {
              value: target[2].data[i],
              // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
            };
          }
        } else {
          console.log("barr", target[3].data[i]);
          target[3].data[i] = {
            value: target[3].data[i],
            // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
          };
        }
      }
    }
  }

  useEffect(() => {
    addBarBorderRadius(option);
  }, [])


  return (
    <>
      <ReactEcharts
        style={{
          height: setting.height,
          width: setting.width,
          margin: '0 auto',
        }}
        option={option}
        onEvents={onEvents}
      />
    </>
  );
});

export default IESStackedBarOverviewChart;