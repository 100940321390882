import { CircularProgress, Divider, emphasize, MenuItem, Select, Tab, Tabs, Typography } from "@material-ui/core";
import { observer } from "mobx-react"
import React, { useEffect, useState } from 'react';
import useStores from "../../hooks/use-stores";
import IESLineRace from "../charts/iesLineRace";
import IESRadar from "../charts/iesRadar";
import IESStudentBehaviour from "../charts/iesStudentBehaviour";
import { BAR_RADIUS, CHART_HEIGHT, CHART_WIDTH, PRIORITY_SUBJECT_1, PRIORITY_SUBJECT_2 } from '../../constants/style';
import Button from '@material-ui/core/Button';
import TabPanel from '../tab-panel';
import { useTranslation } from 'react-i18next';
import IESStageGauage from "../charts/iesStageGauge";
import IESSpeedGauage from "../charts/iesSpeedGauge";
import IESTemperatureGauage from "../charts/iesTemperatureGauge"
import VMCard from "../collapsed-card";
import IESBarPieChart from "../charts/iesBarPieChart";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import IESMultiBarChart from "../charts/iesMultiBarChart";
import IESStackedBarChart from "../charts/IESStackedBarChart";
import { toJS } from "mobx";
import TabDivider from "../tab-divider";
import IESStackedBarOverviewChart from "../charts/IESStackedBarChart-Overview";

enum TaskPropModel {
  NON_SUB = "Non Submission",
  NOT_YET = "Not Yet Submitted",
  ON_TIME = "On Time Rate",
  ON_TIME_SUB = "On Time Submission",
  OVER_DUE_SUB = "Over Due Submission",
  TOTAL = "Total Task",
}

enum SortMode {
  BY_NAME = "By Name",
  BY_AMOUNT = "By Amount",
}

function TabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary border-b border-b-barPrimary font-bold' : 'text-black'} `
  };
}

const StudentDetailFilter = observer(({ callback, semesterId }) => {
  const { filterStore } = useStores();
  const { t } = useTranslation();
  const [year, setYear] = useState<string>("");
  const [semester, setSemester] = useState(0);
  const [semesterList, setSemesterList] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const DEFAULT_YEAR = new Date().getFullYear().toString();

  const onChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as string);
    setSemester(0);
    setSemesterList([]);
    filterStore.getSemesterBasedOnYear(event.target.value as string);
    if (filterStore.semesterList.length > 0) {
      var found = false;
      for (var sem of filterStore.semesterList) {
        setSemester(sem.id);
        setSemesterList([sem.id])
        var selectedSemester = filterStore.semesterList.find(e => e.id == sem.id);
        var startTerm = toJS(selectedSemester).academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = toJS(selectedSemester).academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
        found = true;
        break;
      }
      if (!found) {
        setSemester(0);
        setSemesterList([]);
      }
    }
  }

  useEffect(() => {
    // filterStore.getSemesterDateList().then(() => {
    // setYear(DEFAULT_YEAR);
    if (filterStore.academicYearList.length > 0) {
      // for (var ay of filterStore.academicYearList) {
      //   if (ay.yearDescription == DEFAULT_YEAR) {
      //     setYear(ay.yearDescription);
      //     filterStore.getSemesterBasedOnYear(ay.yearDescription);
      //     break;
      //   }
      // }
      // filterStore.getCurrentSemester();
      // setSemester(filterStore.currentSemester);
      // setSemesterList([filterStore.currentSemester]);
      // var selectedSemester = filterStore.semesterList.find(e => e.id == filterStore.currentSemester);
      // if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
      //   var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
      //   var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
      //   setStartDate(startTerm.startDate);
      //   setEndDate(endTerm.endDate);
      // }
      let fromDate: string = "";
      let toDate: string = "";
      for (var year of filterStore.academicYearList) {
        if (year.academicSemesters.find(s => s.id == semesterId[0])) {
          setYear(year.yearDescription);
          let semesterObj = year.academicSemesters.find(s => s.id == semesterId[0]);
          fromDate = semesterObj.academicTerms[0].academicTermDates.find((data) => data.yearLevelId > 0).startDate;
          if (semesterId.length > 1) {
            let semesterObj2 = year.academicSemesters.find(s => s.id == semesterId[1]);
            toDate = semesterObj2.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
          } else {
            toDate = semesterObj.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
          }
          if (semesterId.length > 1) setSemester(0)
          else setSemester(semesterId[0]);
          setStartDate(fromDate);
          setEndDate(toDate);
          setSemesterList([filterStore.currentSemester])
          break;
        }
      }
    }

    // });
  }, [semesterId, filterStore.academicYearList]);

  const onChangeSemster = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSemester(event.target.value as number);
    setSemesterList([event.target.value as number]);

    if (event.target.value != 0) {
      var selectedSemester = filterStore.semesterList.find(e => e.id == (event.target.value as number));
      if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
        var startTerm = toJS(selectedSemester).academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = toJS(selectedSemester).academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
      }
    } else {
      if (filterStore.semesterList.length > 0) {
        let semList = [filterStore.semesterList[0].id, filterStore.semesterList[1].id];
        setSemesterList(semList);

        // ===== First Sem ===== //
        var firstSem = filterStore.semesterList.find(e => e.id == semList[0]);
        if (firstSem.academicTerms[0].academicTermDates.length > 0) {
          var startTerm = firstSem.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
          setStartDate(startTerm.startDate);
        }
        // ===== Sec Sem ===== //
        var secSem = filterStore.semesterList.find(e => e.id == semList[1]);
        if (secSem.academicTerms[0].academicTermDates.length > 0) {
          var endTerm = secSem.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
          setEndDate(endTerm.endDate);
        }
      }
    }
  }

  return (
    <div className="mb-4 shadow-lg rounded-xl border">
      <div className="grid grid-cols-5 gap-8 p-4">
        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-4 mt-1">
            {t('YEAR')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-full px-2"
            style={{ height: 30 }}
            value={year}
            onChange={onChangeYear}
          >
            {
              filterStore.academicYearList &&
              filterStore.academicYearList.map((ay: any, index) => (
                <MenuItem key={`academic_year_list_${ay.id}_${index}`} value={ay.yearDescription}>
                  {ay.yearDescription}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-4 mt-1">
            {t('SEMESTER')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-full px-2"
            style={{ height: 30 }}
            value={semester}
            onChange={onChangeSemster}
            disabled={year == ""}
          >
            <MenuItem value={0}>{t('ALL')}</MenuItem>
            {
              filterStore.semesterList.length > 0 &&
              filterStore.semesterList.map((sem, index) => (
                <MenuItem key={`semester_list_${sem.id}_${index}`} value={sem.id}>
                  {sem.semesterDescription.substring(8, 10)}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="w-full text-right">
          <Button
            className="bg-buttonPrimary text-white text-sm"
            onClick={() => {
              callback({
                year,
                semesterList,
                startDate,
                endDate
              })
            }}
          >
            {t('SEARCH')}
          </Button>
        </div>
      </div>
    </div>
  );
});

const OverviewTab = observer(({ student, onActiveSubject, semesterId }) => {
  const { studentStore, filterStore } = useStores();
  const { t } = useTranslation();
  const submissionCardStyle = "flex justify-between p-2 border border-gray-300";
  const [overallSubmissionOnTimeRate, setOverallSubmissionOnTimeRate] = useState<number>(0);
  const [submissionOverviewData, setSubmissionOverviewData] = useState<any>([]);
  const [filteredSubmissionData, setFilteredSubmissionData] = useState<any>([]);
  const [filteredxAxis, setFilteredXAxis] = useState<any>([]);
  const [submissionPieChartData, setSubmissionPieChartData] = useState<any>([]);
  const [barChartDisplay, setBarChartDisplay] = useState<TaskPropModel>(TaskPropModel.ON_TIME_SUB);
  const [pieChartTableDisplay, setPieChartTableDisplay] = useState<string>("OverAll");
  const [scoreDetail, setScoreDetail] = useState<any>([]);
  const [scoreDetailSeries, setScoreDetailSeries] = useState<any>([]);
  const [sortMode, setSortMode] = useState<SortMode>(SortMode.BY_AMOUNT);
  const [stackedSubmissionXAxis, setStackedSubmissionXAxis] = useState<any>([]);
  const [stackedSubmissionSeries, setStackedSubmissionSeries] = useState<any>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [naplanList, setNaplanList] = useState<any[]>([]);
  const [currentPageSemester, setCurrentPageSemester] = useState<number[]>(semesterId);
  const [semesterDesc, setSemesterDesc] = useState<string>("");
  const [scoreAttendanceData, setScoreAttendanceData] = useState<any>({
    gpaRating: 0,
    attendanceRate: 0,
  });

  useEffect(() => {
    onProcessAllCharts(semesterId);
    studentStore.getStudentOverviewPATAndNaplanDataList(student)
      .then((res) => {
        let tempList = res.naplanList;
        setNaplanList(tempList.reverse());
      });
  }, [student, filterStore.currentSemester]);

  const onApplyFilter = (dataInfo: any) => {
    onProcessAllCharts(dataInfo.semesterList);
  }

  const onProcessAllCharts = (semesterIdList: number[]) => {
    if (filterStore.academicYearList.length > 0) {
      setCurrentPageSemester(semesterIdList);
      let fromDate: string = "";
      let toDate: string = "";
      let tempDesc = "";
      for (var year of filterStore.academicYearList) {
        if (year.academicSemesters.find(s => s.id == semesterIdList[0])) {
          tempDesc += year.yearDescription;
          let semesterObj = year.academicSemesters.find(s => s.id == semesterIdList[0]);
          fromDate = semesterObj.academicTerms[0].academicTermDates.find((data) => data.yearLevelId > 0).startDate;
          if (semesterIdList.length > 1) {
            tempDesc += " All Semesters";
            let semesterObj2 = year.academicSemesters.find(s => s.id == semesterIdList[1]);
            toDate = semesterObj2.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
          } else {
            tempDesc += " " + year.academicSemesters.find(s => s.id == semesterIdList[0]).semesterDescription;
            toDate = semesterObj.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
          }
          setSemesterDesc(tempDesc);
          break;
        }
      }
      studentStore.getStudentOverallScoreAttendanceRate({
        // semesterIdList: [35],
        semesterIdList: semesterIdList,
        uidList: [student]
      }).then((response) => {
        setScoreAttendanceData({
          gpaRating: +response[0].score,
          attendanceRate: +response[0].attendanceRate
        })
      })

      studentStore.getStudentTaskOverview({
        // semesterIdList: [35],
        semesterIdList: semesterIdList,
        uid: student,
        // fromDate: "2019-02-01",
        // toDate: "2019-04-23"
        fromDate, toDate,
      }).then((response) => {
        if (response.length > 0) {
          let overallRecord = response.find((data) => data.label === "OverAll");
          setOverallSubmissionOnTimeRate(response.find((data) => data.label !== "OverAll") && response.find((data) => data.label !== "OverAll").onTimeRate);
          onProcessSubmissionStatus(response);
          setSubmissionOverviewData(response);
          onSortSubmissionStatus(response, sortMode, barChartDisplay);
          setSubmissionPieChartData([
            response.find(data => data.label === "OverAll").nonSubmission > 0 ? { value: overallRecord.nonSubmission, name: 'Non Submission' } : {},
            response.find(data => data.label === "OverAll").notYetSubmitted > 0 ? { value: overallRecord.notYetSubmitted, name: 'Not Yet Submitted' } : {},
            response.find(data => data.label === "OverAll").onTimeSubmission > 0 ? { value: overallRecord.onTimeSubmission, name: 'On Time Submissions' } : {},
            response.find(data => data.label === "OverAll").overDueSubmission > 0 ? { value: overallRecord.overDueSubmission, name: 'Over Due Submission' } : {}
          ]);
        }
      })

      studentStore.getStudentScoreDetail({
        // semesterIdList: [35],
        semesterIdList: semesterIdList,
        uid: student,
        // fromDate: "2019-02-01",
        // toDate: "2019-04-23"
        fromDate, toDate
      }).then((response) => {
        if (response.length > 0) {
          setScoreDetail(response);
          let tempAssScore: any[] = [];
          let tempClassWorkScore: any[] = [];
          let tempDerivedScore: any[] = [];
          for (var data of response.filter((data) => data.label !== "All")) {
            tempAssScore.push(
              parseFloat(data.assessmentScore) == 0 ? { value: data.assessmentScore, label: { show: false } }
                : { value: data.assessmentScore, label: { position: "top" } });
            tempClassWorkScore.push(
              parseFloat(data.classWorkScore) == 0 ? { value: data.classWorkScore, label: { show: false } } :
                { value: data.classWorkScore, label: { position: "top" } });
            tempDerivedScore.push(
              parseFloat(data.derivedScore) == 0 ? { value: data.derivedScore, label: { show: false } } :
                { value: data.derivedScore, label: { position: "top" } });
          }
          let tempSeries = [
            { name: "Assessment Score", type: "bar", data: tempAssScore, label: { show: true, } },
            { name: "Class Work Score", type: "bar", data: tempClassWorkScore, label: { show: true, } },
            { name: "Derived Score", type: "bar", data: tempDerivedScore, label: { show: true, } }
          ]
          console.log(tempSeries);
          setScoreDetailSeries(tempSeries);
        }
      })
    }
  }

  const onProcessSubmissionStatus = (sourceData: any) => {
    let noOverallData = sourceData.filter(s => s.label !== "OverAll");
    setPieChartTableDisplay(noOverallData[0] && noOverallData[0].label);
    let tempXAxis = noOverallData.map(subject => subject.label);
    setStackedSubmissionXAxis(tempXAxis);

    let nonSubmission: any[] = [];
    let noYetSubmitted: any[] = [];
    let onTimeSubmission: any[] = [];
    let overDueSubmission: any[] = [];

    let nonSubmissionAssess: any[] = [];
    let noYetSubmittedAssess: any[] = [];
    let onTimeSubmissionAssess: any[] = [];
    let overDueSubmissionAssess: any[] = [];

    for (var subject of noOverallData) {
      if (subject.dataList.length > 0) {
        if (subject.dataList.find(s => s.type === "Assessment")) {
          let assessment = subject.dataList.find(s => s.type === "Assessment");
          nonSubmissionAssess.push(
            { value: assessment.nonSubmission, label: { show: assessment.nonSubmission > 0 } });
          noYetSubmittedAssess.push(
            { value: assessment.notYetSubmitted, label: { show: assessment.notYetSubmitted > 0 } });
          onTimeSubmissionAssess.push(
            { value: assessment.onTimeSubmission, label: { show: assessment.onTimeSubmission > 0 } });
          overDueSubmissionAssess.push(
            { value: assessment.overDueSubmission, label: { show: assessment.overDueSubmission > 0 } });
        } else {
          nonSubmissionAssess.push(
            { value: 0, label: { show: false } });
          noYetSubmittedAssess.push(
            { value: 0, label: { show: false } });
          onTimeSubmissionAssess.push(
            { value: 0, label: { show: false } });
          overDueSubmissionAssess.push(
            { value: 0, label: { show: false } });
        }
        if (subject.dataList.find(s => s.type === "ClassWork")) {
          let classWork = subject.dataList.find(s => s.type === "ClassWork");
          nonSubmission.push(
            { value: classWork.nonSubmission, label: { show: classWork.nonSubmission > 0 } });
          noYetSubmitted.push(
            { value: classWork.notYetSubmitted, label: { show: classWork.notYetSubmitted > 0 } });
          onTimeSubmission.push(
            { value: classWork.onTimeSubmission, label: { show: classWork.onTimeSubmission > 0 } });
          overDueSubmission.push(
            { value: classWork.overDueSubmission, label: { show: classWork.overDueSubmission > 0 } });
        } else {
          nonSubmission.push(
            { value: 0, label: { show: false } });
          noYetSubmitted.push(
            { value: 0, label: { show: false } });
          onTimeSubmission.push(
            { value: 0, label: { show: false } });
          overDueSubmission.push(
            { value: 0, label: { show: false } });
        }
      }

    }

    let series = [
      {
        name: "Non Submission",
        type: "bar",
        stack: "classWork",
        emphasis: { focus: 'series' },
        data: nonSubmission,
      }, {
        name: "On Time Submission",
        type: "bar",
        stack: "classWork",
        emphasis: { focus: 'series' },
        data: onTimeSubmission,
      }, {
        name: "Not Yet Submitted",
        type: "bar",
        stack: "classWork",
        emphasis: { focus: 'series' },
        data: noYetSubmitted,
      }, {
        name: "Over Due Submission",
        type: "bar",
        stack: "classWork",
        emphasis: { focus: 'series' },
        data: overDueSubmission,
      },

      {
        name: "Non Submission",
        type: "bar",
        stack: "assessment",
        emphasis: { focus: 'series' },
        data: nonSubmissionAssess,
      }, {
        name: "On Time Submission",
        type: "bar",
        stack: "assessment",
        emphasis: { focus: 'series' },
        data: onTimeSubmissionAssess,
      }, {
        name: "Not Yet Submitted",
        type: "bar",
        stack: "assessment",
        emphasis: { focus: 'series' },
        data: noYetSubmittedAssess,
      }, {
        name: "Over Due Submission",
        type: "bar",
        stack: "assessment",
        emphasis: { focus: 'series' },
        data: overDueSubmissionAssess,
      },
    ];

    // console.log("Task Overview", series, toJS(tempXAxis));

    setStackedSubmissionSeries(series);
  }

  const onClickBarChart = {
    click: function (params) {
      setPieChartTableDisplay(params.name);
      let overallRecord = submissionOverviewData.find((data) => data.label === params.name);
      setSubmissionPieChartData([
        overallRecord.nonSubmission > 0 ? { value: overallRecord.nonSubmission, name: 'Non Submission' } : {},
        overallRecord.notYetSubmitted > 0 ? { value: overallRecord.notYetSubmitted, name: 'Not Yet Submitted' } : {},
        overallRecord.onTimeSubmission > 0 ? { value: overallRecord.onTimeSubmission, name: 'On Time Submissions' } : {},
        overallRecord.overDueSubmission > 0 ? { value: overallRecord.overDueSubmission, name: 'Over Due Submission' } : {}
      ]);
    },
  };

  const onClickPieChart = {
    click: function (params) {
      setBarChartDisplay(params.name);
      onSortSubmissionStatus(submissionOverviewData, sortMode, params.name);
    },
  };

  const onClickMultiBarChart = {
    click: function (params) {
      let target = studentStore.studentScoreDetail.find(s => s.label === params.name)
      onActiveSubject([params.name, target.domainId, target.semesterIdList[0], target.year]);
    },
  };

  const onSortSubmissionStatus = (sourceData: any, mode: SortMode, displayType: TaskPropModel) => {
    setFilteredSubmissionData([]);
    const compare = (a, b) => {
      if (displayType === TaskPropModel.ON_TIME_SUB ? (+a.onTimeSubmission > +b.onTimeSubmission) :
        displayType === TaskPropModel.NON_SUB ? (+a.nonSubmission > +b.nonSubmission) :
          displayType === TaskPropModel.NOT_YET ? (+a.noYetSubmitted > +b.noYetSubmitted) :
            displayType === TaskPropModel.OVER_DUE_SUB ? (+a.overDueSubmission > +b.overDueSubmission)
              : (+a.onTimeRate > +b.onTimeRate)) return -1;
      if (displayType === TaskPropModel.ON_TIME_SUB ? (+a.onTimeSubmission < +b.onTimeSubmission) :
        displayType === TaskPropModel.NON_SUB ? (+a.nonSubmission < +b.nonSubmission) :
          displayType === TaskPropModel.NOT_YET ? (+a.noYetSubmitted < +b.noYetSubmitted) :
            displayType === TaskPropModel.OVER_DUE_SUB ? (+a.overDueSubmission < +b.overDueSubmission)
              : (+a.onTimeRate < +b.onTimeRate)) return 1;
      return 0;
    }
    let originalData = sourceData;
    let filterResult = mode === SortMode.BY_NAME ? originalData.sort((a, b) => ('' + a.label).localeCompare(b.label))
      : sourceData.sort(compare);
    if (mode === SortMode.BY_NAME) {
      let overAllData = filterResult.pop();
      filterResult.unshift(overAllData);
    }
    setFilteredSubmissionData(filterResult);
    setSortMode(mode);
  }

  const onChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  }

  return (
    <>
      <StudentDetailFilter callback={onApplyFilter} semesterId={semesterId} />
      <VMCard titleClassName="mt-4 ml-4 text-xl" title={t('OVERVIEW') + `: ${semesterDesc}`}>
        <div className="grid grid-cols-2 mt-0" style={{ height: 250, overflow: 'hidden' }}>
          <IESSpeedGauage setting={{ height: 350, width: '100%', value: scoreAttendanceData.gpaRating }} />
          {/* <IESTemperatureGauage setting={{ height: 300, width: '100%', value: overallSubmissionOnTimeRate }} /> */}
          <IESStageGauage setting={{ height: 300, width: '100%', value: scoreAttendanceData.attendanceRate }} />
        </div>
        <div className="grid grid-cols-2 gap-4 text-center mb-6 text-barPrimary">
          <div >Academic Average</div>
          {/*<div>{t('SUBMISSION')}</div>*/}
          {/* <div>{t('SUBMISSION_RATE')}</div> */}
          <div>{t('ATTENDANCE_RATE')}</div>
        </div>
      </VMCard>

      <VMCard className="my-4" titleClassName="mt-4 mb-2 ml-4 text-xl" title={t('STANDARDISED_TEST')}>
        {studentStore.sPATNaplanList.naplanList.length > 0 &&
          <Tabs
            value={tabValue}
            onChange={onChangeTabValue}
            TabIndicatorProps={{ style: { display: "none" } }}
            className="border-b border-t mb-4"
          >
            <Tab value={0} label={"NAPLAN" + " " + (naplanList.length > 0 && `${naplanList[0].year}`)} {...TabProps(0, 0 === tabValue)} />
            {naplanList.length == 2 && <TabDivider />}
            {naplanList.length == 2 &&
              <Tab value={1} label={"NAPLAN" + " " + naplanList[1].year}{...TabProps(1, 1 === tabValue)} />}
          </Tabs>}
        <div className="p-6 pt-0">
          {naplanList.length > 0 &&
            naplanList.map((naplan: any, index: number) => (
              <TabPanel value={tabValue} index={index} paddingValue={0}>
                <div className={`text-barPrimary font-bold mb-2`}>
                  NAPLAN {naplan.year}: {t('YEAR_LEVEL')} {naplan.yearLevel}
                </div>
                <table width="100%">
                  <thead className="bg-barPrimary text-white text-sm text-center">
                    <td className="border-r p-2">{t('STRAND')}</td>
                    <td className="border-r p-2">{t('STUDENT_RESULT')}</td>
                    <td className="border-r p-2">{t('STUDENT_RESULT_BAND')}</td>
                    <td className="border-r p-2">{t('LEVEL_DESCRIPTION')}</td>
                  </thead>
                  <tbody className="text-sm text-center">
                    {naplan.data.map((nData: any, index: number) => (
                      <tr key={`naplan_detail_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                        <td className="p-2">{nData.strandName}</td>
                        <td className="p-2">{nData.studentResult}</td>
                        <td className={`font-bold p-2 ${+nData.studentResultBand >= 7 ? 'text-green-500' : +nData.studentResultBand >= 4 ? 'text-yellow-500' : 'text-red-500'}`}>
                          {nData.studentResultBand}</td>
                        <td className="p-2">{nData.levelDescription}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TabPanel>
            ))}

          {studentStore.sPATNaplanList.patList.length > 0 &&
            <>
              <div className={`text-barPrimary font-bold mt-4 mb-2`}>PAT {t('DETAIL')}</div>
              <table width="100%">
                <thead className="bg-barPrimary text-white text-sm text-center">
                  <td className="border-r p-2">{t('PAT_NAME')}</td>
                  <td className="border-r p-2">{t('COMPLETED_DATE_TIME')}</td>
                  <td className="border-r p-2">{t('SCORE')}</td>
                  <td className="border-r p-2">{t('SCALE')}</td>
                  <td className="border-r p-2">{t('PERCENTILE')}</td>
                  <td className="border-r p-2">Stanine</td>
                  <td className="border-r p-2">{t('LEVEL_DESCRIPTION')}</td>

                </thead>
                <tbody className="text-sm text-center">
                  {studentStore.sPATNaplanList.patList.map((detail: any, index: number) => (
                    <tr key={`pat_detail_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                      <td className="p-2">{detail.name}</td>
                      <td className="p-2">{detail.completedTime.split("T")[0] + ' ' + detail.completedTime.split("T")[1]}</td>
                      <td className="p-2">{detail.score}</td>
                      <td className="p-2">{detail.scale}</td>
                      <td className="p-2">{detail.percentile}</td>
                      <td className={`font-bold p-2 ${detail.stanine >= 7 ? 'text-green-500' : detail.stanine >= 4 ? 'text-yellow-500' : 'text-red-500'}`}>{detail.stanine}</td>
                      <td className="p-2">{detail.levelDescription}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>}
        </div>
      </VMCard>

      {
        scoreDetail && scoreDetailSeries.length > 0 &&
        <VMCard className="my-4" titleClassName="my-4 ml-4 text-xl" title={t('STUDENT_SCHOOL_TASK_STATUS') + `: ${semesterDesc}`}>
          <div className="grid grid-cols-4 gap-4 m-4">
            <div className="text-center">
              <div className="bg-barPrimary text-white rounded-t-lg py-1">{t('AVG_SCORE')}</div>
              <div className="border rounded-b-lg py-4 text-2xl">{scoreDetail.find((data) => data.label === "All").overallScore}</div>
            </div>
            <div className="text-center">
              <div className="bg-barPrimary text-white rounded-t-lg py-1">{t('ASSESSMENT_SCORE')}</div>
              <div className="border rounded-b-lg py-4 text-2xl">{scoreDetail.find((data) => data.label === "All").assessmentScore}</div>
            </div>
            <div className="text-center">
              <div className="bg-barPrimary text-white rounded-t-lg py-1">{t('CLASS_WORK_SCORE')}</div>
              <div className="border rounded-b-lg py-4 text-2xl">{scoreDetail.find((data) => data.label === "All").classWorkScore}</div>
            </div>
            <div className="text-center">
              <div className="bg-barPrimary text-white rounded-t-lg py-1">{t('DERVIED_SCORE')}</div>
              <div className="border rounded-b-lg py-4 text-2xl">{scoreDetail.find((data) => data.label === "All").derivedScore}</div>
            </div>
          </div>

          <IESMultiBarChart
            setting={{
              height: 300,
              width: '100%',
              xAxis: scoreDetail.filter((data) => data.label !== "All").map((data) => data.label),
              series: scoreDetailSeries
            }}
            onEvents={onClickMultiBarChart}
          />
          <div className="pb-6" />
        </VMCard>
      }

      <VMCard className="my-4" titleClassName="my-4 ml-4 text-xl" title={t('SUBJECT_TASKS_SUBMISSION_STATUS') + `: ${semesterDesc}`}>
        <div className="mx-4 text-lg text-center text-barPrimary">
          {/* {barChartDisplay === TaskPropModel.NON_SUB ? t('NON_SUBMISSION')
            : barChartDisplay === TaskPropModel.NOT_YET ? t('NON_YET_SUBMITTED') :
              barChartDisplay === TaskPropModel.ON_TIME ? t('ON_TIME_RATE') :
                barChartDisplay === TaskPropModel.OVER_DUE_SUB ? t('OVER_DUE_SUBMISSION')
                  : t('ON_TIME_SUBMISSION')} {t('OVERVIEW')} */}
          {/*{t('CURRENT')}: {pieChartTableDisplay}*/}
        </div>
        <div className="text-sm text-barPrimary mx-4" style={{ marginTop: -10 }}>Tip: Left Bar = Class Work, Right Bar = Assessments</div>
        <IESStackedBarOverviewChart
          setting={{
            xAxis: stackedSubmissionXAxis,
            series: stackedSubmissionSeries,
            height: 400,
            width: '100%'
          }}
          onEvents={onClickBarChart}
        />
        {/* <div className="flex justify-end mt-4">
          <div className="text-barPrimary pt-1 mr-4 font-semibold">{t('ORDER_BY')}:</div>
          <Button
            className={`rounded-lg text-white text-sm mr-4 ${sortMode === SortMode.BY_NAME ? "bg-barPrimary" : "bg-gray-400"}`}
            component="span"
            onClick={() => onSortSubmissionStatus(submissionOverviewData, SortMode.BY_NAME, barChartDisplay)}
          >
            {t('BY_NAME')}
          </Button>
          <Button
            className={`rounded-lg text-white text-sm mr-4 ${sortMode === SortMode.BY_AMOUNT ? "bg-barPrimary" : "bg-gray-400"}`}
            component="span"
            onClick={() => onSortSubmissionStatus(submissionOverviewData, SortMode.BY_AMOUNT, barChartDisplay)}
          >
            {t('BY_AMOUNT')}
          </Button>
        </div>
        <IESBarPieChart
          setting={{
            barChart: {
              xAxis: filteredSubmissionData.length > 0 ? filteredSubmissionData.map(data => data.label) : [],
              value: filteredSubmissionData.length > 0 ? filteredSubmissionData
                .map(data => barChartDisplay === TaskPropModel.ON_TIME_SUB ? data.onTimeSubmission :
                  barChartDisplay === TaskPropModel.NON_SUB ? data.nonSubmission :
                    barChartDisplay === TaskPropModel.NOT_YET ? data.noYetSubmitted :
                      barChartDisplay === TaskPropModel.OVER_DUE_SUB ? data.overDueSubmission
                        : data.onTimeRate) : [],
            },
            height: 300,
            width: '100%'
          }}
          onEvents={onClickBarChart}
        /> */}
        {/* <div className="mx-4 text-lg text-center text-barPrimary">
          {pieChartTableDisplay && `${t('CURRENT')}: ${pieChartTableDisplay}`}
        </div> */}
        <div className="grid grid-cols-5">
          {/* <div className="col-span-2">
            <IESBarPieChart
              setting={{
                pieChart: {
                  value: submissionPieChartData,
                },
                height: 300,
                width: '100%'
              }}
              onEvents={onClickPieChart}
            />
          </div> */}

          {/* {
            submissionOverviewData.length > 0 && submissionOverviewData.find((data) => data.label === pieChartTableDisplay) &&
            <div className="grid grid-cols-2 col-span-5 gap-4 my-4 mx-4" style={{ height: 'fit-content' }}>
              <div className={submissionCardStyle} style={{ height: 'fit-content' }}>
                <div className="text-xs">
                  <div className="text-gray-400 mb-1">{t('SUBMISSION_ON_TIME')}</div>
                  <div>
                    {submissionOverviewData.find((data) => data.label === pieChartTableDisplay).onTimeSubmission
                      ? submissionOverviewData.find((data) => data.label === pieChartTableDisplay).onTimeSubmission : 0} {t('TASKS')}
                  </div>
                </div>
                <div className="text-2xl text-green-400">
                  <AlarmOnOutlinedIcon fontSize={'inherit'} color={'inherit'} />
                </div>
              </div>
              <div className={submissionCardStyle} style={{ height: 'fit-content' }}>
                <div className="text-xs">
                  <div className="text-gray-400 mb-1">{t('TOTAL_TASK')}</div>
                  {submissionOverviewData.find((data) => data.label === pieChartTableDisplay).totalTask
                    ? submissionOverviewData.find((data) => data.label === pieChartTableDisplay).totalTask : 0} {t('TASKS')}
                </div>
                <div className="text-2xl text-gray-500"><FormatListBulletedOutlinedIcon fontSize={'inherit'} color={'inherit'} /></div>
              </div>
              <div className={submissionCardStyle} style={{ height: 'fit-content' }}>
                <div className="text-xs">
                  <div className="text-gray-400 mb-1">{t('ON_TIME_RATE')}</div>
                  {submissionOverviewData.find((data) => data.label === pieChartTableDisplay).onTimeRate ?
                    submissionOverviewData.find((data) => data.label === pieChartTableDisplay).onTimeRate : 0}%
                </div>
                <div className="text-2xl"><TimerOutlinedIcon fontSize={'inherit'} color={'action'} /></div>
              </div>
              <div className={submissionCardStyle} style={{ height: 'fit-content' }}>
                <div className="text-xs">
                  <div className="text-gray-400 mb-1">{t('NOT_YET_SUBMITTED')}</div>
                  {submissionOverviewData.find((data) => data.label === pieChartTableDisplay).notYetSubmitted ?
                    submissionOverviewData.find((data) => data.label === pieChartTableDisplay).notYetSubmitted : 0} {t('TASKS')}
                </div>
                <div className="text-2xl"><HelpOutlineOutlinedIcon fontSize={'inherit'} color={'secondary'} /></div>
              </div>
              <div className={submissionCardStyle} style={{ height: 'fit-content' }}>
                <div className="text-xs">
                  <div className="text-gray-400 mb-1">{t('NON_SUBMISSION')}</div>
                  {submissionOverviewData.find((data) => data.label === pieChartTableDisplay).nonSubmission ?
                    submissionOverviewData.find((data) => data.label === pieChartTableDisplay).nonSubmission : 0} {t('TASKS')}
                </div>
                <div className="text-2xl text-sky-400">
                  <DateRangeOutlinedIcon fontSize={'inherit'} color={'inherit'} />
                </div>
              </div>
              <div className={submissionCardStyle} style={{ height: 'fit-content' }}>
                <div className="text-xs">
                  <div className="text-gray-400 mb-1">{t('OVER_DUE_SUBMISSION')}</div>
                  {submissionOverviewData.find((data) => data.label === pieChartTableDisplay) ?
                    submissionOverviewData.find((data) => data.label === pieChartTableDisplay).overDueSubmission : 0} {t('TASKS')}
                </div>
                <div className="text-2xl">
                  <EventBusyOutlinedIcon fontSize={'inherit'} color={'error'} />
                </div>
              </div>
            </div>
          } */}

        </div>
      </VMCard>
    </>
  )
})
export default OverviewTab;