import React, { useEffect, useState, useRef } from "react";
import IESVBar from "../charts/iesVBar";
import { observer } from "mobx-react";
import useStores from "../../hooks/use-stores";
import { Button, CircularProgress, Divider, Tab, Tabs } from "@material-ui/core";
import { toJS } from "mobx";
import IESBoxPlot from "../charts/iesBoxplot";
import { BAR_RADIUS, CHART_WIDTH, THEME_CLASS } from "../../constants/style";
import TabPanel from "../tab-panel";
import { useTranslation } from 'react-i18next';
import AcademicOverview from "./overview";
import IESLineRace from "../charts/iesLineRace";
import VMCard from "../collapsed-card";
import IESLargeAreaChart from "../charts/iesLargeAreaChart";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SlideDown from "react-slidedown";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IESPunchCard from "../charts/iesPunchCard";
import { onConvertLearnerAttributeToIndex } from "../../constants/options";
import IESLineAreaChart from "../charts/iesLineAreaChart";
import TabDivider from "../tab-divider";

const SubjectAbbrConvert = {
  "GPA": "AA",
  "English": "E",
  "Food": "F",
  "Health and Physical Education": "H&PE",
  "Humanities": "H",
  "Languages ": "L",
  "Mathematics": "M",
  "Religious Education": "R",
  "Research and Investigation": "R&I",
  "Science": "S",
  "The Arts": "TA",
  "Pathways": "PA",
  "Technologies": "T",
  "Community and Careers": "CC",
  "Performing Arts": 'PAS',
  "STEM and Enterprise": 'STEM'

}

enum DisplayMode {
  SUBJECT = "Subject",
  SEMESTER = "Semester",
}

enum GrowthDomain {
  GPA = "GPA",
  ENG = "English",
  FOOD = "Food",
  HEALTH = "Health and Physical Education",
  HUMAN = "Humanities",
  LANG = "Languages ",
  MATH = "Mathematics",
  PATH = "Pathways",
  RELIG = "Religious Education",
  RESEARCH = "Research and Investigation",
  SCIENCE = "Science",
  TECH = "Technologies",
  ART = "The Arts",
  CC = "Community and Careers",
  PAS = "Performing Arts",
  STEM = "STEM and Enterprise"
}

enum PerformanceDisplayMode {
  YEARLY = 0,
  SEMESTER = 0,
}

function TabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}
const AcademicAtSchoolTab = observer(({ location, subject, target, date }) => {
  const { studentStore, filterStore } = useStores();
  const { t } = useTranslation();
  const [atSchoolData, setAtSchoolData] = useState<any>();
  const [classCodeChartData, setClassCodeChartData] = useState<any>();
  const [activedClassCode, setActivedClassCode] = useState<string>("");
  const [performanceByTaskData, setPerformanceByTaskData] = useState<any>();
  const [performanceByClassData, setPerformanceByClassData] = useState<any>();
  const [averageScore, setAverageScore] = useState<number>(0);
  const [tabValue, setTabValue] = useState<number>(0);
  const [atSchoolInfoTabValue, setAtSchoolInfoTabValue] = useState<number>(0);
  const [legend, setLegend] = useState<any>();
  const [selectedLegend, setSelectedLegend] = useState<any>();
  const [xAxis, setXAxis] = useState<any>();
  const [performanceData, setPerformanceData] = useState<any>();
  const [showAllLegend, setShowAllLegend] = useState<boolean>(false);
  const [sOverviewData, setSOverviewData] = useState<any>([]);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.SUBJECT);
  const [areaMinMax, setAreaMinMax] = useState<any>({ min: -20, max: 20 });
  const [growthXAxis, setGrowthXAxis] = useState<any>([]);
  const [recordPref, setRecordPref] = useState<any>([]);
  const [selectedDomainId, setSelectedDomainId] = useState<number>(0);
  const [legendDropdown, setLegendDropDown] = useState<boolean>(false);
  const [growthChartDisplay, setGrowthChartDisplay] = useState<string>(GrowthDomain.GPA);
  const [learnerAttributeData, setLearnerAttributeData] = useState<any>({ yAxis: [], assessmentDataList: [] });
  const [yearlyLearnerAttributeData, setYearlyLearnerAttributeData] = useState<any>({
    legend: [],
    selectedLegend: [],
    xAxis: [],
    dataSeries: []
  });
  const [yearlyLearnerAttributeByClassData, setYearlyLearnerAttributeByClassData] = useState<any>({
    legend: [],
    selectedLegend: [],
    xAxis: [],
    dataSeries: []
  });
  const [refreshDisplay, setRefreshDisplay] = useState<any>([]);
  const [performanceYearlyDisplayMode, setPerformanceYearlyDisplayMode] = useState<boolean>(false);
  let colorLibray = ["#2d6d2f", "#3f3fbf", "#ffa500", "#c0717f", "#b655b4"];

  const onChangeSelectedLegend = (tempLegend: any, showAllLegend: boolean) => {
    let selected = {};

    let index = 0;
    for (var legend of tempLegend) {
      if (showAllLegend) {
        selected[legend] = true;
      } else {
        if (legend != "GPA") selected[legend] = false;
        else selected[legend] = true;
      }
      index++;
    }

    console.log("Temp legend", showAllLegend, selected);
    setSelectedLegend(selected);
  }
  const myRef = useRef(null);

  useEffect(() => {
    target(myRef);
    studentStore.getIndividualStudentOverviewDomainData(location.state.student)
      .then((res) => {
        setSOverviewData(
          res.sort((a, b) => a.label == "GPA" ? -1 : (b.label == "GPA" ? 1 : a.label.localeCompare(b.label)))
        );
        if (res && res.length > 0) {
          setGrowthXAxis(res[0].data.map(d => d.year + ' S' + d.semester));
          var min = Math.min(...res.find(data => data.label == "GPA").data.map(data => data.growthRate));
          var max = Math.max(...res.find(data => data.label == "GPA").data.map(data => data.growthRate));
          setAreaMinMax({
            min: min - 5,
            max: max + 5,
          });
        }
        onProcessYearlyPerformanceOverview(res, PerformanceDisplayMode.SEMESTER);
        // console.log("test", res.sort((a, b) => a.label == "GPA" ? -1 : (b.label == "GPA" ? 1 : a.label.localeCompare(b.label))));
      });
    studentStore.getStudentYearlyLearnerAttribute(location.state.student).then((res) => {
      if (res.length > 0) {
        let attributeXAxis = res.map(r => r.semesterDescription);
        let attributeLegend: string[] = res.find(r => r.attributeInfo.length > 0).attributeInfo.map(r => r.attribute);
        let attributeData: any[] = [];
        for (var legend of attributeLegend) {
          let tempData: number[] = [];
          for (var data of res) {
            if (data.attributeInfo.length == 0) tempData.push(0);
            else tempData.push(data.attributeInfo.find(a => a.attribute === legend).score);
          }
          attributeData.push({ name: legend, type: 'line', data: tempData, smooth: true });
        }

        setYearlyLearnerAttributeData({
          legend: attributeLegend,
          selectedLegend: attributeLegend,
          xAxis: attributeXAxis,
          dataSeries: attributeData,
        })
      }

    });
  }, [location.state]);

  useEffect(() => {
    if (studentStore.atSchoolData && Object.keys(studentStore.atSchoolData).length > 0) {
      setAtSchoolData(studentStore.atSchoolData);
      // onRequestAtSchoolData(subject[1], subject[3], subject[2], false);
      if (selectedDomainId > 0) {
        setAverageScore(
          (studentStore.atSchoolData.chartData.filter(d => d.domainId == selectedDomainId)
            .reduce((s, a) => s + a.amount, 0)) / studentStore.atSchoolData.chartData.filter(d => d.domainId == selectedDomainId).length
        )
      } else {
        setAverageScore(
          (studentStore.atSchoolData.chartData
            .reduce((s, a) => s + a.amount, 0)) / studentStore.atSchoolData.chartData.length
        )
      }

      // // ======================================
      // // Prepare student vs class by class code
      // // =======================================
      // let tempBoxData = [];
      // let tempCat = [];
      // let tempScore = [];
      // for (var task of studentStore.atSchoolData.classPerformance) {
      //   tempBoxData.push(task.doubleList);
      //   tempCat.push(task.category);
      //   let studentScore = studentStore.atSchoolData.chartData.filter((data) => data.label == task.category)[0];
      //   tempScore.push(studentScore.amount);
      // }

      // setPerformanceByClassData({
      //   boxData: tempBoxData,
      //   category: tempCat,
      //   studentScore: tempScore
      // });
    }
  }, [studentStore.atSchoolData]);

  useEffect(() => {
    setActivedClassCode(subject[0]);
    if (subject) {
      onRequestAtSchoolData(subject[1], subject[3], subject[2], true);
      setGrowthChartDisplay(sOverviewData.length > 0 ? sOverviewData.find(o => o.domainId == subject[1]).label : growthChartDisplay);
      setTabValue(0);
    }
  }, [subject])

  const onClickChartBar = {
    click: function (params) {
      setActivedClassCode(params.name);
      prepareSingleClassCodeData(params.name);
      let targetSubjectClass = atSchoolData.chartData.find(sc => sc.label == params.name);
      studentStore.getStudentLearnerAttributeBySubjectClass(targetSubjectClass.studentId, targetSubjectClass.subjectClassId)
        .then((res) => onProcessLearnerAttribute(res));
      studentStore.getStudentYearlyLearnerAttributeBySubject(location.state.student, targetSubjectClass.subjectClassId)
        .then((res) => onProcessYearlyLearnerAttributeByClass(res));


    },
  };

  const onProcessYearlyPerformanceOverview = (sourceData: any, displayYearly: boolean) => {
    setPerformanceYearlyDisplayMode(displayYearly);
    let tempXAxis: any[] = [];
    let tempData: any[] = [];
    if (sourceData && sourceData.length > 0) {
      if (!displayYearly) tempXAxis = sourceData[0].data.map(d => d.year + ' S' + d.semester);
      else tempXAxis = tempXAxis = sourceData[0].yearlyData.map(d => d.year);
      setXAxis(tempXAxis);

      let tempLegend = sourceData.filter((data) => data.label != "GPA").map(data => data.label);
      tempLegend.unshift("GPA");
      setLegend(tempLegend);
      onChangeSelectedLegend(tempLegend, false);

      for (var data of sourceData) {
        let tempValue: any[] = [];
        if (!displayYearly) {
          tempValue = data.data.map(d => d.data);
        } else {
          tempValue = data.yearlyData.map(d => d.data);
        }
        if (data.label != "GPA") {
          tempData.push({ name: data.label, type: 'line', data: tempValue, smooth: true })
        } else {
          tempData.unshift({ name: data.label, type: 'line', data: tempValue, smooth: true, })
        }
      }
      // remove 0 in the end
      let index = 0;
      while (index < tempData.length) {
        if (tempData[index].data[tempData[index].data.length - 1] == 0) {
          tempData[index].data.pop();
        } else {
          index++;
        }
      }

      setPerformanceData(tempData);
      setRefreshDisplay([]);
    }
  }

  const prepareSingleClassCodeData = (target: string, data?: any) => {
    if (data || atSchoolData.taskList.length > 0) {
      data = data ? data : atSchoolData.taskList;
      console.log("data", toJS(data));

      let tempResult = { label: [], amount: [] }
      let selectedTaskLists = data.filter((t) => t.classCode == target);
      for (var i = 0; i < selectedTaskLists.length; i++) {
        let id = i + 1;
        tempResult.label.push("Task ID:" + id);
        tempResult.amount.push(selectedTaskLists[i].percentage)
      }
      setClassCodeChartData(tempResult);

      // ===================================
      // Prepare Performance comparison data
      // ===================================
      let tempBoxData = [];
      let tempCat = [];
      let tempScore = [];
      let index = 1;
      for (var task of studentStore.atSchoolData.taskList) {
        if (task.classCode == target) {
          tempBoxData.push(task.classTaskPerformance);
          tempCat.push("Task ID: " + index);
          tempScore.push(+task.percentage);
          index++;
        }
      }
      console.log("WDNMD", {
        boxData: toJS(tempBoxData),
        category: tempCat,
        studentScore: tempScore
      }, target, toJS(studentStore.atSchoolData.taskList));


      setPerformanceByTaskData({
        boxData: tempBoxData,
        category: tempCat,
        studentScore: tempScore
      });
    }
  }

  const onChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  }

  const onChangeAtSchoolDetailTabValue = (event, newValue) => {
    setAtSchoolInfoTabValue(newValue);
  }

  const onRequestAtSchoolData = (domainId: number, year: any, semester: any, activeSubject?: boolean) => {
    if (filterStore.academicYearList.length > 0) {
      let semList = filterStore.academicYearList.find(ay => ay.yearDescription === year.toString()).academicSemesters;
      let semRecord = semester == 1 ? semList[0] : semester == 2 ? semList[1] : semList.find(s => s.id == semester);
      let req = {
        uid: location.state.student,
        semesterIdList: [semRecord.id],
        fromDate: semRecord.academicTerms[0].academicTermDates.filter(data => data.yearLevelId > 0)[0].startDate,
        toDate: semRecord.academicTerms[1].academicTermDates.filter(data => data.yearLevelId > 0)[0].endDate,
      }
      console.log(toJS(sOverviewData), toJS(sOverviewData.find(data => data.domainId == domainId)));
      if (sOverviewData.length == 0) {
        setAreaMinMax({ min: -30, max: 30 })
      } else {
        var min = Math.min(...sOverviewData.find(data => data.domainId == domainId).data.map(data => data.growthRate));
        var max = Math.max(...sOverviewData.find(data => data.domainId == domainId).data.map(data => data.growthRate));
        setAreaMinMax({
          min: min - 5,
          max: max + 5,
        });
      }
      console.log("Overview", toJS(req), semester, toJS(semRecord), toJS(semList), subject, "min", min, "max", max);
      setRecordPref([year, semester > 2 ? semRecord.semesterDescription.split(' ')[1] : semester]);
      setSelectedDomainId(domainId ? domainId : 0);
      if (!activeSubject) {
        studentStore.getStudentAcadamicAtSchool(req)
          .then(() => {
            onProcessByClassBoxPlot(domainId ? domainId : 0);
            setLearnerAttributeData({ yAxis: [], data: [], data2: [] });
            setPerformanceByTaskData({ boxData: [], category: [], studentScore: [] });
            if (myRef.current && myRef.current.offsetTop) {
              window.scroll({
                top: myRef.current.offsetTop,
                left: 0,
                behavior: 'smooth'
              });
            }
            setActivedClassCode("");
          });
      } else {
        studentStore.getStudentAcadamicAtSchool(req).then((res) => {
          // setActivedClassCode(subject[0]);
          onProcessByClassBoxPlot(domainId ? domainId : 0);
          setLearnerAttributeData({ yAxis: [], data: [], data2: [] });
          setPerformanceByTaskData({ boxData: [], category: [], studentScore: [] });
          let targetSubjectClass = res.chartData.find(sc => sc.label == subject[0]);
          studentStore.getStudentLearnerAttributeBySubjectClass(targetSubjectClass.studentId, targetSubjectClass.subjectClassId)
            .then((res) => onProcessLearnerAttribute(res));
          studentStore.getStudentYearlyLearnerAttributeBySubject(location.state.student, targetSubjectClass.subjectClassId)
            .then((res) => onProcessYearlyLearnerAttributeByClass(res))
          prepareSingleClassCodeData(subject[0], studentStore.atSchoolData.taskList);
          window.scroll({
            top: myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
          });
          setActivedClassCode(subject[0]);
        })
      }
    }
  }

  const onProcessLearnerAttribute = (response: any) => {
    let tempYAxis: string[] = [];
    let tempData: any[] = [];
    let tempData2: any[] = [];

    let assessmentData: any = {};
    let assessmentDataList: any = [];
    let tempAsseId = 0;
    let reportType: any[] = [];
    if (response.length > 0) {
      tempAsseId = response[0].assessmentComponetId;
      reportType = [...new Set(response.map(r => r.assessmentType))];
    }

    let index = 0;
    reportType.map((type: any, typeIndex: number) => {
      let typeList: any[] = [];
      response.filter(r => r.assessmentType === type)
        .map((r: any) => {
          tempYAxis.push(r.attribute);
          typeList.push([onConvertLearnerAttributeToIndex(r.scoreLevel) + 1, index, 10]);
          index++;
          // if (r.assessmentComponetId == tempAsseId) {
          //   tempData.push([index, onConvertLearnerAttributeToIndex(r.scoreLevel) + 1, 10]);
          // } else {
          //   if (reportType.length == 1) reportType.push(r.assessmentType);
          //   tempData2.push([index, onConvertLearnerAttributeToIndex(r.scoreLevel) + 1, 10]);
          // }
        });
      assessmentData[type] = {
        name: type,
        type: 'scatter',
        symbolSize: function (val) { return val[2] * 2; },
        data: typeList,
        animationDelay: function (idx) { return idx * 5; },
        itemStyle: { color: colorLibray[typeIndex % colorLibray.length] }
      };
      assessmentDataList.push(assessmentData[type]);
    })

    console.log("learner", assessmentDataList);

    setLearnerAttributeData({ yAxis: tempYAxis, assessmentDataList, reportType });
  }

  const onProcessYearlyLearnerAttributeByClass = (response: any) => {
    if (response.length > 0) {
      let attributeXAxis = response.map(r => r.semesterDescription);
      let attributeLegend: string[] = response.find(r => r.attributeInfo.length > 0).attributeInfo.map(r => r.attribute);
      let attributeData: any[] = [];
      for (var legend of attributeLegend) {
        let tempData: number[] = [];
        for (var data of response) {
          if (data.attributeInfo.length == 0) tempData.push(0);
          else tempData.push(data.attributeInfo.find(a => a.attribute === legend).score);
        }
        attributeData.push({ name: legend, type: 'line', data: tempData, smooth: true });
      }
      setYearlyLearnerAttributeByClassData({
        legend: attributeLegend,
        selectedLegend: attributeLegend,
        xAxis: attributeXAxis,
        dataSeries: attributeData,
      })
    }

  }

  const onProcessByClassBoxPlot = (domainId: number) => {
    if (studentStore.atSchoolData && studentStore.atSchoolData.classPerformance) {
      let tempBoxData: any[] = [];
      let tempCat: any[] = [];
      let tempScore: any[] = [];

      if (domainId == 0) {
        studentStore.atSchoolData.classPerformance.map((task: any) => {
          tempBoxData.push(task.doubleList);
          tempCat.push(task.category);
          let studentScore = studentStore.atSchoolData.chartData.filter((data) => data.label == task.category)[0];
          tempScore.push(studentScore.amount);
        })
      } else {
        studentStore.atSchoolData.classPerformance.filter(cp => cp.domainId == domainId)
          .map((task: any) => {
            tempBoxData.push(task.doubleList);
            tempCat.push(task.category);
            let studentScore = studentStore.atSchoolData.chartData.filter((data) => data.label == task.category)[0];
            tempScore.push(studentScore.amount);
          })
      }
      setPerformanceByClassData({
        boxData: tempBoxData,
        category: tempCat,
        studentScore: tempScore
      });
    }
    // window.scroll({
    //   top: myRef.current.offsetTop,
    //   left: 0,
    //   behavior: 'smooth'
    // });
  }

  return (
    <>
      <VMCard title={t('STUDENT_YEARLY_PERFORMANCE_OVERVIEW')} titleClassName="text-xl m-4" className="mt-4">
        {
          studentStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            <>
              <div className="flex justify-end">
                <Button
                  className={`rounded-lg text-white bg-barPrimary text-sm mb-8`}
                  component="span"
                  onClick={() =>
                    onProcessYearlyPerformanceOverview(studentStore.sOverviewDomainData, !performanceYearlyDisplayMode)}
                >
                  {!performanceYearlyDisplayMode ? 'Change to Yearly View' : 'Change to Semesters View'}
                </Button>
                <Button
                  className={`rounded-lg text-white bg-barPrimary text-sm mb-8 mx-4`}
                  component="span"
                  onClick={() => {

                  }}
                >
                  {showAllLegend ? t('SHOW_ONLY_GPA') : t('SHOW_ALL_SUBJECTS')}
                </Button>
              </div>
              <div className="px-4">
                <IESLineRace
                  dataOptions={{
                    legend: legend,
                    selectedLegend: selectedLegend,
                    xAxis: xAxis,
                    yAxisName: "%",
                    dataSeries: performanceData
                  }}
                />
              </div>
              <div className={`flex justify-between m-4`}>
                <div className="text-barPrimary font-semibold text-lg">{t('STUDENT_PERFORMANCE_OVERVIEW')}</div>
                <Button
                  className={`rounded-lg text-white bg-barPrimary text-sm`}
                  component="span"
                  onClick={() => setDisplayMode(displayMode === DisplayMode.SEMESTER ? DisplayMode.SUBJECT : DisplayMode.SEMESTER)}
                >
                  {t('TOGGLE_DISPLAY_MODE')}
                </Button>
              </div>

              <button
                type="button"
                onClick={() => setLegendDropDown(!legendDropdown)}
                className="text-barPrimary mx-4"
              >
                {t('LEGENDS')}<KeyboardArrowDownIcon color='inherit' />
              </button>
              <SlideDown
                className={'pure-menu pure-menu-scrollable '}
                closed={!legendDropdown}
              >
                <div className="grid grid-cols-3 mx-4 text-gray-500 text-sm border-t border-b p-2">
                  <div className="text-barPrimary font-bold">S - {t('SCORE')}</div>
                  <div className="text-barPrimary font-bold">G - {t('GROWTH')}</div>
                  <div />
                  <div>AA - Academic Average</div>
                  <div>CC - Community and Careers</div>
                  <div>E - {t('ENGLISH')}</div>
                  <div>F - {t('FOOD')}</div>
                  <div>H&PE - {t('HEALTH_PHYSICAL_EDUCATION')}</div>
                  <div>H - {t('HUMANITIES')}</div>
                  <div>L - {t('LANGUAGES')}</div>
                  <div>M - {t('MATH')}</div>
                  <div>R - {t('RELIGIOUS_EDUCATION')}</div>
                  <div>R&I - {t('RESEARCH_INVESTIGATION')}</div>
                  <div>S - {t('SCIENCE')}</div>
                  <div>STEM - STEM and Enterprise</div>
                  <div>TA - {t('ART')}</div>
                  <div>T - {t('TECHNOLOGIES')}</div>
                  <div>PA - {t('PATHWAYS')}</div>
                  <div>PAS - Performing Arts</div>
                </div>
              </SlideDown>
              {/* Dev tip: if domain label missing indicate new domain is added to the system, new domain abbr & label conversion is needed */}
              <div className="overflow-auto p-2">
                <table className="w-full" style={{ margin: "0 auto" }}>
                  <thead className="text-xs font-semibold">
                    <tr className="bg-barPrimary text-white">
                      <td></td>
                      {sOverviewData.length > 0 &&
                        displayMode === DisplayMode.SEMESTER ? sOverviewData[0].data
                          // .filter(data => data.year != new Date().getFullYear()).map((title, index) =>
                          .map((title, index) =>
                          (
                            <td key={'at_school_overview_title_' + index} colSpan={2} className="text-center border-l border-white p-1 text-xs">
                              {title.year} S{title.semester}
                            </td>
                          ))
                        :
                        sOverviewData.map((title, index) =>
                        (
                          <th key={'at_school_overview_title_' + index} className="text-center border-l border-white p-1 text-xs" colSpan={2}>
                            {SubjectAbbrConvert[title.label]}
                          </th>
                        ))
                      }
                    </tr>
                    <tr className="border-l border-r border-gray-200 text-xs">
                      <td></td>
                      {sOverviewData.length > 0 && displayMode === DisplayMode.SEMESTER ?
                        sOverviewData[0].data.map(() => (
                          <>
                            <td className="text-center border-l border-gray-200 p-2 font-bold text-barPrimary">S</td>
                            <td className="border-l border-gray-200 text-center p-2 font-bold text-barPrimary">G</td>
                          </>
                        ))
                        : sOverviewData.map(() => (
                          <>
                            <td className="text-center border-l border-gray-200 p-2 font-bold text-barPrimary">S</td>
                            <td className="border-l border-gray-200 text-center p-2 font-bold text-barPrimary">G</td>
                          </>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody className="border border-t-0">
                    {sOverviewData.length > 0 && (displayMode === DisplayMode.SEMESTER ?
                      sOverviewData.map((data, index) =>
                      (
                        <tr
                          key={'at_school_overview_data_list_' + index}
                          className={`${index % 2 != 0 && 'bg-gray-100'} text-sm hover:bg-blue-200`}
                        >
                          <th style={{ width: 80, paddingRight: 5, textAlign: 'right' }}>{SubjectAbbrConvert[data.label]}</th>
                          {data.data.map((value) => (
                            <>
                              <td className="text-center border-l border-gray-200 p-1 text-xs">
                                <button
                                  className="hover:underline hover:text-white hover:font-bold"
                                  onClick={() => {
                                    onRequestAtSchoolData(data.domainId, value.year, value.semester);
                                    setGrowthChartDisplay(data.label);
                                    console.log("At school", data.label);
                                  }}>
                                  {value.data}
                                </button>
                              </td>
                              <td className={`text-center border-l border-gray-200 p-1 text-xs ${value.growthRate > 0 ? 'text-green-600' : value.growthRate < 0 && value.growthRate > -100 && 'text-red-600'}`}>
                                {value.growthRate == 0 || value.growthRate == -100 ? " - " : value.growthRate + "%"}
                                <span>
                                  {value.growthRate > 0 ? <ArrowUpwardIcon color={'inherit'} fontSize={'inherit'} />
                                    : value.growthRate < 0 && value.growthRate > -100 && <ArrowDownwardIcon color={'inherit'} fontSize={'inherit'} />}
                                </span>
                              </td>
                            </>
                          ))}
                        </tr>
                      ))
                      :
                      sOverviewData[0].data.map((date, index) =>
                      (
                        <tr
                          key={'at_school_overview_data_list_' + index}
                          className={`${index % 2 != 0 && 'bg-gray-100'} text-sm hover:bg-blue-200`}
                        >
                          <th style={{ width: 80, paddingRight: 5, textAlign: 'right' }}>{date.year} S{date.semester}</th>
                          {sOverviewData.map(data => [data.label, data.domainId]).map((subject) => (
                            sOverviewData.find(data => data.label === subject[0])
                              // .data.filter(data => data.year == date.year && data.semester == date.semester && data.year != new Date().getFullYear())
                              .data.filter(data => data.year == date.year && data.semester == date.semester)
                              .map((value) => (
                                <>
                                  <td className="text-center border-l border-gray-200 p-1 text-xs">
                                    <button
                                      className="hover:underline hover:text-white hover:font-bold"
                                      onClick={() => {
                                        onRequestAtSchoolData(subject[1], date.year, date.semester);
                                        setGrowthChartDisplay(subject[1]);
                                      }}>
                                      {value.data}
                                    </button>
                                  </td>
                                  <td className={`text-center border-l border-gray-200 p-1 text-xs ${value.growthRate > 0 ? 'text-green-600' : value.growthRate < 0 && value.growthRate > -100 && 'text-red-600'}`}>
                                    {value.growthRate == 0 || value.growthRate == -100 ? " - " : value.growthRate + "%"}
                                    <span>
                                      {value.growthRate > 0 ? <ArrowUpwardIcon color={'inherit'} fontSize={'inherit'} />
                                        : value.growthRate < 0 && value.growthRate > -100 && <ArrowDownwardIcon color={'inherit'} fontSize={'inherit'} />}
                                    </span>

                                  </td>
                                </>
                              ))
                          ))}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                <div className="text-barPrimary text-sm m-2">Tip: Click score in the above table to view student performance below within the specified domain & semester</div>
              </div>
            </>
        }
      </VMCard>

      {
        selectedDomainId >= 0 && recordPref.length > 0 &&
        <VMCard
          className="mt-4"
          titleClassName="m-4 text-xl"
          title={isNaN(growthChartDisplay) ? "Academic Average" :
            sOverviewData.find(data => data.domainId === growthChartDisplay).label === "GPA" ? "Academic Average" :
              sOverviewData.find(data => data.domainId === growthChartDisplay).label}
        >
          <Tabs
            value={tabValue}
            onChange={onChangeTabValue}
            className="border-t border-b"
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab label={t('BY_SCORE')} value={0} {...TabProps(0, 0 === tabValue)} />
            <TabDivider />
            <Tab label={t('BY_CLASS')} value={1} {...TabProps(1, 1 === tabValue)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <div className="text-xl text-center mb-4 mt-8 text-barPrimary">
              {t('STUDENT_AVG_SCORE')}: <span className="font-bold">{averageScore ? averageScore.toFixed(2) : t('NO_RESULT')}</span> <b>({recordPref[0]} {t('SEMESTER')} {recordPref[1]})</b>
            </div>
            {
              (studentStore.loading || !atSchoolData)
                ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <>
                  {/* <div className="text-center my-4 text-barPrimary">
                  {t('STUDENT_AVG_SCORE')}: <span className="font-bold">{averageScore ? averageScore.toFixed(2) : t('NO_RESULT')}</span>
                  </div> */}
                  <IESVBar
                    dataAndSetting={{
                      data: {
                        names: selectedDomainId != 0 ? atSchoolData.chartData.filter(data => data.domainId === selectedDomainId).map((labels) => labels.label)
                          : atSchoolData.chartData.map((labels) => labels.label),
                        values: selectedDomainId != 0 ? atSchoolData.chartData.filter(data => data.domainId === selectedDomainId).map((labels) => labels.amount)
                          : atSchoolData.chartData.map((labels: any, index: number) => ({
                            value: labels.amount,
                            itemStyle: {
                              // barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0],
                              color: THEME_CLASS[0].chartColorLibrary[index % THEME_CLASS[0].chartColorLibrary.length]
                            },
                          })),
                      },
                      height: "300px",
                      width: "90%",
                      showDataZoom: false,
                      xAxisLabelRotate: 0,
                      barMaxWidth: 30,
                      yMax: 100,
                    }}
                    callback={onClickChartBar}
                  />
                  {/* <div className="text-center text-red-400">
                <small>Note: Due to lack of data, some class codes may not be displayed here</small>
              </div> */}
                </>
            }

            <div ref={myRef} className="mt-6" />
            <div className="mb-10 px-10 w-full text-center">
              {
                activedClassCode !== "" ?
                  studentStore.loading || !atSchoolData ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                    :
                    <>
                      <Tabs
                        value={tabValue}
                        onChange={onChangeAtSchoolDetailTabValue}
                        className="border-t border-b mb-4"
                        TabIndicatorProps={{ style: { display: "none" } }}
                      >
                        <Tab value={0} label={t('TASK_PERFORMANCE')} {...TabProps(0, 0 === atSchoolInfoTabValue)} />
                        <TabDivider />
                        <Tab value={1} label={t('MARKING_FEEDBACK')} {...TabProps(1, 1 === atSchoolInfoTabValue)} />
                        <TabDivider />
                        <Tab value={2} label={t('LEARNER_ATTRIBUTE')} {...TabProps(2, 2 === atSchoolInfoTabValue)} />
                      </Tabs>

                      <TabPanel value={atSchoolInfoTabValue} index={0}>
                        <div className="text-center my-4 text-barPrimary text-xl">
                          {t('CLASSCODE')}: {activedClassCode}
                        </div>
                        <div className="text-center mb-4 text-barPrimary text-xl">
                          {t('STUDENT_PERFORMANCE_IN_TASK_VS_CLASS_PERFORMANCE')}
                        </div>
                        {
                          atSchoolData.taskList.length > 0 ?
                            // <IESVBar
                            //   dataAndSetting={{
                            //     data: {
                            //       names: classCodeChartData ? classCodeChartData.label : [],
                            //       values: classCodeChartData ? classCodeChartData.amount : [],
                            //     },
                            //     height: "420px",
                            //     width: "90%",
                            //     showDataZoom: false,
                            //     xAxisLabelRotate: 0,
                            //     barMaxWidth: 30,
                            //     yMax: 100,
                            //   }}
                            // />
                            <IESBoxPlot
                              dataAndSetting={{
                                data: performanceByTaskData ? performanceByTaskData : { boxData: [[0]], category: ["No Result"] },
                                height: '350px',
                                width: CHART_WIDTH,
                                xAxisLabelRotate: 45,
                                markComparisionData: performanceByTaskData && performanceByTaskData.studentScore,
                              }}
                            />
                            : <div className="w-full py-4 text-center">{t('NO_RESULT')}</div>
                        }
                      </TabPanel>

                      <TabPanel value={atSchoolInfoTabValue} index={1}>
                        <div className="text-center my-4 text-barPrimary text-xl">
                          {t('CLASSCODE')}: {activedClassCode}
                        </div>
                        <table className="w-full" style={{ margin: "0 auto" }}>
                          <thead className="bg-barPrimary text-white text-sm">
                            <th className="p-2">{t('TASK')} ID</th>
                            <th className="p-2">{t('CLASSCODE')}</th>
                            <th className="p-2">{t('TASK_TITLE')}</th>
                            <th className="p-2">{t('SCORE')}</th>
                            <th className="p-2">{t('MARKING_FEEDBACK')}</th>
                          </thead>
                          <tbody className="border border-t-0 text-sm">
                            {atSchoolData && atSchoolData.taskList.filter((t) => t.classCode == activedClassCode)
                              .map((taskList, index) =>
                              (
                                <tr key={'at_school_data_task_list_' + index} className={`text-center ${index % 2 != 0 && 'bg-gray-100'}`}>
                                  <td className="border-r p-2">{index + 1}</td>
                                  <td className="border-r p-2">
                                    {taskList.classCode}
                                  </td>
                                  <td className="border-r p-2 text-left">{taskList.taskTitle}</td>
                                  <td className="border-r p-2">
                                    {taskList.percentage}
                                  </td>
                                  <td className="p-2 text-left">
                                    {taskList.markingFeedback == "" || !taskList.markingFeedback ?
                                      'No feedback yet.'
                                      : taskList.markingFeedback}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </TabPanel>

                      <TabPanel value={atSchoolInfoTabValue} index={2}>
                        <div className="text-center mb-2 text-barPrimary text-xl">
                          {t('CLASSCODE')}: {activedClassCode}
                        </div>
                        <div className="text-center mb-4 text-barPrimary text-xl">
                          {t('LEARNER_ATTRIBUTE')} <b>({recordPref[0]} {t('SEMESTER')} {recordPref[1]})</b>
                        </div>
                        <div className="flex justify-center mb-2">
                          {learnerAttributeData.assessmentDataList && learnerAttributeData.assessmentDataList.map((ad: any, index: number) => (
                            <div className="flex mr-4" style={{ color:  colorLibray[index % colorLibray.length] }}><span style={{ display: 'block', background: colorLibray[index % colorLibray.length], width: 15, height: 15 }} className="rounded-full mt-1 mr-2" /> {ad.name}</div>
                          ))}

                          {/* <div className="flex" style={{ color: '#3f3fbf' }}><span style={{ display: 'block', background: '#3f3fbf', width: 15, height: 15 }} className="rounded-full mt-1 mr-2" /> {learnerAttributeData.reportType && learnerAttributeData.reportType[1]}</div> */}
                        </div>
                        {studentStore.loadingLearnerAttribute ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                          :
                          <IESPunchCard
                            setting={{
                              height: 400,
                              width: "80%",
                              yAxis: learnerAttributeData.yAxis,
                              data: learnerAttributeData.assessmentDataList
                            }} />
                        }
                      </TabPanel>

                      {/* 
                      <div className="text-center mb-4 text-barPrimary text-xl" style={{ marginTop: -20 }}>
                        {t('CLASSCODE')}: {activedClassCode}
                      </div>
                      <div className="text-barPrimary text-xl my-4">
                        {t('COMPARISION_OF_STUDENT_YEARLY_LEARNER_ATTRIBUTE')}
                      </div>
                      {studentStore.loadingLearnerAttribute ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                        :
                        <IESLineRace
                          dataOptions={{
                            legend: yearlyLearnerAttributeByClassData.legend,
                            selectedLegend: yearlyLearnerAttributeByClassData.selectedLegend,
                            xAxis: yearlyLearnerAttributeByClassData.xAxis,
                            dataSeries: yearlyLearnerAttributeByClassData.dataSeries,
                            top: "10%",
                            left: "5%",
                            min: 0,
                            max: 3,
                            yAxisShow: false,
                          }}
                        />}
                      */}
                      <br />
                    </>
                  : <div className="w-full py-4 text-center">{t('NO_CLASS_CODE_SELECTED')}</div>
              }
            </div>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <div className="text-center mb-4 text-barPrimary text-xl my-8">
              {t('STUDENT_AVG_MARKS_IN_BOXPLOT')}
            </div>
            {
              studentStore.loading || !atSchoolData ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBoxPlot
                  dataAndSetting={{
                    data: performanceByClassData ? performanceByClassData : { boxData: [[0]], category: [t('NO_RESULT')] },
                    height: '350px',
                    width: CHART_WIDTH,
                    xAxisLabelRotate: 45,
                    markComparisionData: performanceByClassData && performanceByClassData.studentScore
                  }}
                />
            }
          </TabPanel>
        </VMCard>
      }

      {sOverviewData.length > 0 && growthXAxis.length > 0 &&
        <VMCard
          className="mt-4"
          titleClassName="m-4 text-xl"
          title={`${t('GROWTH_OVERVIEW')}: ${isNaN(growthChartDisplay) ? "Academic Average" :
            sOverviewData.find(data => data.domainId === growthChartDisplay).label === "GPA" ? "Academic Average" :
              sOverviewData.find(data => data.domainId === growthChartDisplay).label}`}
        >
          <IESLineAreaChart
            setting={{
              xAxis: growthXAxis,
              seriesData: sOverviewData.find(data => data.label === growthChartDisplay) ?
                sOverviewData.find(data => data.label === growthChartDisplay).data.map(data => data.growthRate)
                : sOverviewData.find(data => data.domainId === growthChartDisplay).data.map(data => data.growthRate),
              height: 300,
              width: '100%',
              min: areaMinMax.min,
              max: areaMinMax.max,
            }}
          />
          {/* <IESLargeAreaChart
            setting={{
              xAxis: growthXAxis,
              positiveData: sOverviewData.find(data => data.label === growthChartDisplay) ?
                sOverviewData.find(data => data.label === growthChartDisplay).data.map(data => data.growthRate >= 0 ? data.growthRate : 0)
                : sOverviewData.find(data => data.domainId === growthChartDisplay).data.map(data => data.growthRate >= 0 ? data.growthRate : 0),
              negativeData: sOverviewData.find(data => data.label === growthChartDisplay) ?
                sOverviewData.find(data => data.label === growthChartDisplay).data
                  .map(data => data.growthRate > -100 && data.growthRate < 0 ? data.growthRate : 0)
                : sOverviewData.find(data => data.domainId === growthChartDisplay).data
                  .map(data => data.growthRate > -100 && data.growthRate < 0 ? data.growthRate : 0),
              height: 300,
              width: "100%",
              min: areaMinMax.min,
              max: areaMinMax.max,
            }}
          /> */}
        </VMCard>}

      <VMCard
        className="mt-4"
        titleClassName="m-4 text-xl"
        title={t('STUDENT_YEARLY_LEARNER_ATTRIBUTE')}
      >
        <IESLineRace
          dataOptions={{
            legend: yearlyLearnerAttributeData.legend,
            selectedLegend: yearlyLearnerAttributeData.selectedLegend,
            xAxis: yearlyLearnerAttributeData.xAxis,
            dataSeries: yearlyLearnerAttributeData.dataSeries,
            top: "10%",
            left: "5%",
            min: 0,
            max: 3,
            yAxisShow: false,
          }}
        />
      </VMCard>
    </>
  );
})

export default AcademicAtSchoolTab;
