import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const IESSpeedGauage = observer(({ setting }) => {
  const { t } = useTranslation();

  var option = {
    grid: {
      top: 0,
      left: 0,
      right: 0,
    },
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 1,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 4,
            color: [
              [0.50, '#55cfe4'],
              [1, '#74a5f7']
            ]
          }
        },
        pointer: {
          icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
          length: '10%',
          width: 20,
          offsetCenter: [0, '-60%'],
          itemStyle: {
            color: 'auto'
          }
        },
        axisTick: {
          length: 10,
          lineStyle: {
            color: 'auto',
            width: 2
          }
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5
          }
        },
        axisLabel: {
          color: '#464646',
          fontSize: 14,
          distance: - 60,
          formatter: function (value) {
            if (value === 0.875) {
              return '85%';
            } else if (value === 0.625) {
              return '60%';
            } else if (value === 0.375) {
              return '35%';
            } else if (value === 0.125) {
              return '15%';
            }
            return '';
          }
        },
        title: {
          offsetCenter: [0, "20%"],
          fontSize: 14
        },
        detail: {
          fontSize: 18,
          offsetCenter: [0, "-20%"],
          valueAnimation: true,
          formatter: function (value) {
            return Math.round(value * 100) + '%';
          },
          color: 'auto',
        },
        radius: '75%',
        data: [
          {
            value: setting.value / 100,
          }
        ]
      }
    ]
  }

  return (
    <>
      <ReactEcharts
        style={{
          height: setting.height,
          width: setting.width,
          paddingTop: 10,
        }}
        option={option}
      />
    </>
  );
});

export default IESSpeedGauage;