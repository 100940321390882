export class CanvasService {

    canvas: any;
    ctx: any;
  
    constructor() { }
  
    getContext(context){
      this.canvas = context.canvas;
      this.ctx = context;
      return [this.canvas, this.ctx];
    }
  
    fillRect(x, y, width, length, color = 'black'){
      this.ctx.fillStyle = color;
      this.ctx.fillRect(x, y, width, length);
    }
  
    strokeRect(x, y, width, length, color = 'black'){
      this.ctx.lineWidth = 0.2;
      this.ctx.strokeStyle = color;
      this.ctx.strokeRect(x, y, width, length);
    }
  
    text(text, x, y, color = 'black', font = "13px Arial"){
      this.ctx.font = font;
      this.ctx.fillStyle = color;
      this.ctx.fillText(text, x, y);
    }
  
    drawPolygon(positions, color, mode = 'fill'){
      this.ctx.fillStyle = color;
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = 1;
      this.ctx.beginPath();
      for(let [i, p] of positions.entries()){
        if(i === 0){
          this.ctx.moveTo(p[0], p[1]);
        }
        else{
          this.ctx.lineTo(p[0], p[1]);
        }
      }
      this.ctx.closePath();
      if(mode == 'fill'){
        this.ctx.fill();
      }
      else{
        this.ctx.stroke();
      }
    }
  
    drawCircle(x, y, r, color, mode){
      this.ctx.beginPath();
      this.ctx.arc(x, y, r, 0, 2 * Math.PI);
      if(mode == 'fill'){
        this.ctx.fillStyle = color;
        this.ctx.fill();
      }
      else{
        this.ctx.strokeStyle = color;
        this.ctx.stroke();
      }
    }
  }