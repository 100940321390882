import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const IESMultiBarChart = observer(({ setting, onEvents = null }) => {
  const { t } = useTranslation();

  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    xAxis: {
      type: 'category',
      data: setting.xAxis,
      axisLabel: {
        margin: 6,
        fontSize: 10,
      }
    },
    series: setting.series
  }

  return (
    <>
      <ReactEcharts
        style={{
          height: setting.height,
          width: setting.width,
          paddingTop: 10,
        }}
        option={option}
        onEvents={onEvents}
      />
    </>
  );
});

export default IESMultiBarChart;