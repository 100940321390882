import React, { useState, useEffect } from "react";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import useStores from "../../hooks/use-stores";
import { observer } from "mobx-react";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import IESStudentBehaviour from "../charts/iesStudentBehaviour";
import { CHART_WIDTH } from "../../constants/style";
import VMCard from "../collapsed-card";
import IESLineRace from "../charts/iesLineRace";

const SocialBehaviorTab = observer(({ location, date }) => {
  const { studentStore, filterStore } = useStores();
  const { t } = useTranslation();
  const [socialBehaviorData, setSocialBehaviorData] = useState<any>();
  const [yearlyBehaviorData, setYearlyBehaviorData] = useState<any>();
  const cardStyle = "shadow-lg rounded-lg bg-barPrimary overflow-hidden hover:shadow-sm";
  enum behaviourType {
    // @ts-ignore
    commendation = t("COMMENDATION"),
    // @ts-ignore
    detention = t("DETENTION"),
    // @ts-ignore
    incident = t("INCIDENT"),
    // @ts-ignore
    suspension = t("SUSPENSION"),
  }
  const [currentBehaviour, setCurrentBehaviour] = useState<behaviourType>(behaviourType.commendation);

  const behaviorCardValue = {
    unsolvedIncidents: 0,
    unprocessedDetentions: 0,
    pendingDetentions: 0,
    missDententions: 0,
    unprocessedSuspensions: 0,
    pendingSuspensions: 0
  }

  useEffect(() => {
    studentStore.getIndividualStudentOverviewBehaviorData(location.state.student)
      .then(() => onProcessBehaviorData());
  }, [location.state])

  useEffect(() => {
    if (studentStore.behaviorData) {
      setSocialBehaviorData(studentStore.behaviorData);
      // behaviorCardValue.unsolvedIncidents
      //   = studentStore.behaviorData.incidentList.length > 0 && studentStore.behaviorData.incidentList.filter(e => !e.incidentResolvedFlag).length;
      // behaviorCardValue.unprocessedDetentions
      //   = studentStore.behaviorData.detentionList.length > 0 && studentStore.behaviorData.detentionList.filter(e => !e.detentionAssignedFlag).length;
      // behaviorCardValue.pendingDetentions
      //   = studentStore.behaviorData.detentionList.length > 0 && studentStore.behaviorData.detentionList.filter(e => e.detentionAssignedFlag && !e.detentionAttendedFlag).length;
      // behaviorCardValue.missDententions
      //   = studentStore.behaviorData.detentionList.filter(e => e.dismissalFlag).length;
      // behaviorCardValue.unprocessedSuspensions
      //   = studentStore.behaviorData.suspensionList.filter(e => !e.letterGeneratedFlag).length;
      // behaviorCardValue.pendingDetentions
      //   = studentStore.behaviorData.suspensionList.filter(e => e.letterGeneratedFlag && !e.dismissalFlag).length;
    }

  }, [studentStore.behaviorData]);

  const onProcessBehaviorData = () => {
    let detensionData = {
      name: "Detention",
      type: "line",
      data: studentStore.sOverviewBehaviorData.find(b => b.label == "Detention").data.map(b => +b.data),
      smooth: true,
    }
    let suspensionData = {
      name: "Suspension",
      type: "line",
      data: studentStore.sOverviewBehaviorData.find(b => b.label == "Suspension").data.map(b => +b.data),
      smooth: true,
    }
    let commendationData = {
      name: "Commendation",
      type: "line",
      data: studentStore.sOverviewBehaviorData.find(b => b.label == "Commendation").data.map(b => +b.data),
      smooth: true,
    }
    let incidentData = {
      name: "Incident",
      type: "line",
      data: studentStore.sOverviewBehaviorData.find(b => b.label == "Incident").data.map(b => +b.data),
      smooth: true,
    }
    setYearlyBehaviorData({
      legend: studentStore.sOverviewBehaviorData.map(b => b.label),
      xAxis: studentStore.sOverviewBehaviorData[0].data.map(b => b.year + " S" + b.semester),
      dataSeries: [detensionData, suspensionData, commendationData, incidentData]
    })
  }

  return (
    <VMCard className="mt-4" titleClassName="my-4">
      <div className="p-8 pt-0">
        {
          yearlyBehaviorData && yearlyBehaviorData.legend && yearlyBehaviorData.xAxis && yearlyBehaviorData.dataSeries &&
          <>
            <div className="text-barPrimary text-xl mb-8">{t('STUDENT_BEHAVIOR_OVERVIEW')}</div>
            <IESLineRace
              dataOptions={{
                legend: yearlyBehaviorData.legend,
                xAxis: yearlyBehaviorData.xAxis,
                dataSeries: yearlyBehaviorData.dataSeries,
                selectedLegend: true
              }}
            />
          </>
        }
        {
          studentStore.loading || !socialBehaviorData ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            <>
              <div className="text-xl text-barPrimary mt-4">{t('MANAGEMENT_OVERVIEW')} <b>({date})</b></div>
              <div className="grid grid-cols-4 gap-4 my-4">
                <button
                  type="button"
                  className={cardStyle}
                  onClick={() => setCurrentBehaviour(behaviourType.commendation)}
                >
                  <div className="text-white p-2">{t('COMMENDATION')}</div>
                  <div className="bg-white py-4 text-2xl">{socialBehaviorData && socialBehaviorData.commendationList.length}</div>
                </button>
                <button
                  type="button"
                  className={cardStyle}
                  onClick={() => setCurrentBehaviour(behaviourType.detention)}
                >
                  <div className="text-white p-2">{t('DETENTION')}</div>
                  <div className="bg-white py-4 text-2xl">{socialBehaviorData && socialBehaviorData.detentionList.length}</div>
                </button>
                <button
                  type="button"
                  className={cardStyle}
                  onClick={() => setCurrentBehaviour(behaviourType.incident)}
                >
                  <div className="text-white p-2">{t('INCIDENT')}</div>
                  <div className="bg-white py-4 text-2xl"> {socialBehaviorData && socialBehaviorData.incidentList.length}</div>
                </button>
                <button
                  type="button"
                  className={cardStyle}
                  onClick={() => setCurrentBehaviour(behaviourType.suspension)}
                >
                  <div className="text-white p-2">{t('SUSPENSION')}</div>
                  <div className="bg-white py-4 text-2xl">{socialBehaviorData && socialBehaviorData.suspensionList.length}</div>
                </button>
              </div>
            </>
        }

        {
          studentStore.loading || !socialBehaviorData ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            <>
              <div className="text-xl my-4 text-barPrimary">{t('VIEW')} {currentBehaviour}</div>
              <table className="text-left">
                <thead className="text-white bg-barPrimary">
                  <th className="border-r p-3 text-center w-1/5">{t('DATE')}</th>
                  <th className="border-r p-3 text-center w-1/4">{t('SOCIAL_INDICATOR_VALUE')}</th>
                  <th className="border-r p-3 text-center">{t('CATEGORY')}</th>
                  <th className="p-3 text-center">{t('NOTES')}</th>
                </thead>
                <tbody>
                  {socialBehaviorData && currentBehaviour == behaviourType.commendation
                    ? socialBehaviorData.commendationList.map((commendation, index) => (
                      <tr key={commendation.commendationId} className={`text-center ${index % 2 != 0 && 'bg-gray-100'}`}>
                        <td className="border-r p-3 text-center  w-1/5">
                          {commendation.commendationDate && commendation.commendationDate.split("T")[0]}
                        </td>
                        <td className="border-r p-3 text-center  w-1/4">
                          {commendation.socialIndicatorValue}
                        </td>
                        <td className="border-r p-3 text-left">
                          {commendation.commendationCategoryNavigation.categoryDescription}
                        </td>
                        <td className="p-3 text-left">
                          {commendation.commendationTitle}
                        </td>
                      </tr>
                    ))
                    :
                    socialBehaviorData && currentBehaviour == behaviourType.incident
                      ? socialBehaviorData.incidentList.map((incident, index) => (
                        <tr key={incident.incidentId} className={`text-center ${index % 2 != 0 && 'bg-gray-100'}`}>
                          <td className="border-r p-3 text-center  w-1/5">
                            {incident.incidentDate && incident.incidentDate.split("T")[0]}
                          </td>
                          <td className="border-r p-3 text-center  w-1/4">
                            {incident.socialIndicatorValue}
                          </td>
                          <td className="border-r p-3 text-left">
                            {incident.type.typeDetails}
                          </td>
                          <td className="p-3 text-left">
                            {incident.incidentTitle}
                          </td>
                        </tr>
                      ))
                      :
                      socialBehaviorData && currentBehaviour == behaviourType.detention
                        ? socialBehaviorData.detentionList.map((detention, index) => (
                          <tr key={detention.detentionId} className={`text-center ${index % 2 != 0 && 'bg-gray-100'}`}>
                            <td className="border-r p-3 text-center  w-1/5">
                              {detention.detentionDate && detention.detentionDate.split("T")[0]}
                            </td>
                            <td className="border-r p-3 text-center  w-1/4">
                              {detention.socialIndicatorValue}
                            </td>
                            <td className="border-r p-3 text-left">
                              {detention.detentionDetails}
                            </td>
                            <td className="p-3 text-left">
                              {detention.type.typeDescription}
                            </td>
                          </tr>
                        ))
                        :
                        socialBehaviorData && currentBehaviour == behaviourType.suspension
                        && socialBehaviorData.suspensionList.map((suspension, index) => (
                          <tr key={suspension.suspensionId} className={`text-center ${index % 2 != 0 && 'bg-gray-100'}`}>
                            <td className="border-r p-3 text-center  w-1/5">
                              {suspension.suspensionDate && suspension.suspensionDate.split("T")[0]}
                            </td>
                            <td className="border-r p-3 text-center  w-1/4">
                              {suspension.socialIndicatorValue}
                            </td>
                            <td className="border-r p-3 text-left">
                              {suspension.dismissalReason}
                            </td>
                            <td className="p-3 text-left">
                              {suspension.suspensionDetails}
                            </td>
                          </tr>
                        ))
                  }
                </tbody>
              </table>
            </>
        }
      </div>
    </VMCard>
  );
})

export default SocialBehaviorTab;