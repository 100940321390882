import { observer } from "mobx-react"
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStores from "../../hooks/use-stores";
import TabPanel from "../tab-panel";
import IESRadar from "../charts/iesRadar";
import { Button, CircularProgress, MenuItem, Select } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import IESVBar from "../charts/iesVBar";
import VMCard from "../collapsed-card";
import { BAR_RADIUS, THEME_CLASS } from "../../constants/style";

function tabProps(index: any, isActive) {
  return {
    id: `external-tab-${index}`,
    'aria-controls': `external-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const AcademicPAT = observer(() => {
  const { studentStore } = useStores();
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);
  const [patMathLegend, setPatMathLegend] = useState<any>([]);
  const [patMathIndicator, setPatMathIndicator] = useState<any>();
  const [patMathData, setPatMathData] = useState<any>();
  const [patMathSelectList, setPatMathSelectList] = useState<any>([]);
  const [patMathSelect, setPatMathSelect] = useState<number>(0);
  const [patReadLegend, setPatReadLegend] = useState<any>([]);
  const [patReadIndicator, setPatReadIndicator] = useState<any>();
  const [patReadData, setPatReadData] = useState<any>();
  const [patReadSelectList, setPatReadSelectList] = useState<any>([]);
  const [patReadSelect, setPatReadSelect] = useState<number>(0);

  useEffect(() => {
    if (studentStore.sPatOverviewData && studentStore.sPatOverviewData.length > 0) {
      // First Graph: PAT MATHS
      let mathPerformance = studentStore.sPatOverviewData[0].subjectPerformance;
      let validPerformanceList = mathPerformance.filter(data => data.performance.length > 0)
      setPatMathSelectList(validPerformanceList.map(data => data.number));
      setPatMathSelect(validPerformanceList.length > 0 ? validPerformanceList[0].number : 0);
      if (validPerformanceList && validPerformanceList.length > 0) {
        let indicator = [];
        let tempValue = [];
        for (var data of validPerformanceList[0].performance) {
          indicator.push({
            name: data.classificationName,
            max: 100,
          });
          tempValue.push(data.score);
        }
        setPatMathData([{
          name: "Score",
          value: tempValue
        }]);
        setPatMathLegend([`${studentStore.sPatOverviewData[0].subjectName}`]);
        setPatMathIndicator(indicator);
      }

      // First Graph: PAT Reading
      if (studentStore.sPatOverviewData.length > 1) {
        let readPerformance = studentStore.sPatOverviewData[1].subjectPerformance;
        let validPerformanceList = readPerformance.filter(data => data.performance.length > 0)
        setPatReadSelectList(validPerformanceList.map(data => data.number));
        setPatReadSelect(validPerformanceList.length > 0 ? validPerformanceList[0].number : 0);

        if (validPerformanceList && validPerformanceList.length > 0) {
          let indicator2 = [];
          let tempValue2 = [];
          for (var data of validPerformanceList[0].performance) {
            indicator2.push({
              name: data.classificationName,
              max: 100,
            });
            tempValue2.push(data.score);
          }
          setPatReadData([{
            name: "Score",
            value: tempValue2
          }]);
          setPatReadLegend([`${studentStore.sPatOverviewData[0].subjectName}`]);
          setPatReadIndicator(indicator2);
        }
      }
    }
  }, [studentStore.sPatOverviewData]);

  const onChangeMathsSelection = (event) => {
    setPatMathSelect(event.target.value as number);
    let targetPerformance = studentStore.sPatOverviewData[0].subjectPerformance.find(data => data.number == event.target.value);
    let tempValue = targetPerformance.performance.map(data => data.score);
    setPatMathData([{
      name: "Score",
      value: tempValue
    }]);
  }

  const onChangeReadSelection = (event) => {
    setPatReadSelect(event);
    let targetPerformance = studentStore.sPatOverviewData[0].subjectPerformance.find(data => data.number == event.target.value);
    let tempValue = targetPerformance.performance.map(data => data.score);
    setPatReadData([{
      name: "Score",
      value: tempValue
    }]);
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <VMCard
      titleComponent={
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="external tabs example"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab label={t('OVERVIEW')} {...tabProps(0, 0 == value)} />
          <Tab label={t('DETAIL')} {...tabProps(1, 1 === value)} />
        </Tabs>
      }
    >
      <TabPanel value={value} paddingValue={0} index={0}>
        <div className="grid grid-cols-2">
          {
            studentStore.loadingPAT ? <div className="col-span-2 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              : patMathSelect != 0 &&
              <>
                <div>
                  <div className="flex justify-center">
                    <div className="mt-1 mr-4 text-barPrimary">PAT Maths</div>
                    <div className="text-center">
                      <span className="mr-2">{t('NO')}.</span>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="bg-background w-20"
                        onChange={onChangeMathsSelection}
                        value={patMathSelect}
                      >
                        {patMathSelectList.map((m) => (
                          <MenuItem value={m}>{m}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <IESRadar
                    dataOptions={{
                      radius: '55%',
                      legend: patMathLegend,
                      indicator: patMathIndicator,
                      name: patMathLegend[0],
                      series: patMathData,
                    }}
                  />
                </div>

                {
                  patReadSelect != 0 ?
                    <div>
                      <div className="flex justify-center">
                        <div className="mt-1 mr-4 text-barPrimary">PAT Reading</div>
                        <div className="text-center">
                          <span className="mr-2">{t('NO')}.</span>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="bg-background w-20"
                            onChange={onChangeReadSelection}
                            value={patReadSelect}
                          >
                            {patReadSelectList.map((m) => (
                              <MenuItem value={m}>{m}</MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <IESRadar
                        dataOptions={{
                          radius: '55%',
                          legend: patReadLegend,
                          indicator: patReadIndicator,
                          name: patReadLegend[0],
                          series: patReadData,
                        }}
                      />
                    </div>
                    :
                    <div className="text-center text-lg my-4 text-barPrimary">{t('NO_PAT_READING_RESULT')}</div>
                }
              </>
          }
          {
            patMathSelect == 0 && patReadSelect == 0 &&
            <div className="col-span-2 text-center text-lg my-4 text-barPrimary">{t('NO_PAT_RESULT_FOUND')}</div>
          }
        </div>
      </TabPanel>

      <TabPanel value={value} paddingValue={1} index={1}>
        <AcademicPATDetail />
      </TabPanel>
    </VMCard >
  )
})

const AcademicPATDetail = observer(() => {
  const { studentStore } = useStores();
  const { t } = useTranslation();
  const [activedDetail, setActivedDetail] = useState<any>();
  const [xAxisData, setXAxisData] = useState<any>([]);
  const [schoolXAxisData, setSchoolXAxisData] = useState<any>([]);
  const [yAxisData, setYAxisData] = useState<any>([]);

  const onDisplaySingleDetail = (detail: any) => {
    setActivedDetail(detail);
    setXAxisData(detail.performance.map((data: any) => ({
      value: data.score,
      itemStyle: {
        // barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0],
        color: THEME_CLASS[0].chartColorLibrary[0]
      }
    })));
    setYAxisData(detail.performance.map(data => data.classificationName));
    setSchoolXAxisData(detail.performance.map((data: any) => ({
      value: data.schoolAverageScore,
      itemStyle: {
        // barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0],
        color: THEME_CLASS[0].chartColorLibrary[1]
      }
    })));
  }

  return (
    <>
      {
        studentStore.loadingPATDetail ? <div className="col-span-2 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
          :
          <>
            <table width="100%">
              <thead className="bg-barPrimary text-white text-sm text-center">
                <td className="border-r p-2">{t('TYPE')}</td>
                <td className="border-r p-2">{t('COMPLETED_DATE_TIME')}</td>
                <td className="border-r p-2">{t('SCORE')}</td>
                <td className="border-r p-2">{t('SCALE')}</td>
                <td className="border-r p-2">{t('PERCENTILE')}</td>
                <td className="border-r p-2">Stanine</td>
                <td className="border-r p-2">{t('ACTION')}</td>
              </thead>
              <tbody className="text-sm text-center">
                {
                  studentStore.sPatDetailData.length > 0 &&
                  studentStore.sPatDetailData.map((detail, index) => (
                    <tr key={`pat_detail_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                      <td className="p-2">{detail.name}</td>
                      <td className="p-2">{detail.completedTime.split("T")[0] + ' ' + detail.completedTime.split("T")[1]}</td>
                      <td className="p-2">{detail.score}</td>
                      <td className="p-2">{detail.scale}</td>
                      <td className="p-2">{detail.percentile}</td>
                      <td className="p-2">{detail.stanine}</td>
                      <td className="p-2">
                        <Button
                          className="bg-buttonPrimary text-white text-sm"
                          component="span"
                          onClick={() => onDisplaySingleDetail(
                            studentStore.sPatDetailData.find(data => data.name == detail.name && data.number == detail.number))}
                        >
                          {t('VIEW')}
                        </Button>
                      </td>
                    </tr>
                  ))

                }
              </tbody>
            </table>

            {
              activedDetail &&
              <>
                <div className="my-4 py-4 border-t">
                  <span className="font-bold">{t('TYPE')}: <span className="text-barPrimary">{activedDetail.name}</span></span>
                  <span className="ml-6 font-bold">
                    {t('COMPLETED_DATE_TIME')}: <span className="text-barPrimary">{activedDetail.completedTime.split("T")[0] + ' ' + activedDetail.completedTime.split("T")[1]}</span>
                  </span>
                </div>
                <div className="text-center text-barPrimary text-lg mb-4">{t('STUDENT_PERFORMANCE_IN_THIS_TEST')}</div>
                <div className="flex justify-center text-base">
                  <div className="flex mr-4"><span className="block w-4 h-4 mt-1 mr-2" style={{ background: THEME_CLASS[0].chartColorLibrary[0] }} /> {t('STUDENT_SCORE')}</div>
                  <div className="flex">
                    <span className="w-4 h-4 block mt-1 mr-2" style={{ background: THEME_CLASS[0].chartColorLibrary[1] }} /> {t('SCHOOL_SCORE')}
                  </div>
                </div>
                <IESVBar
                  dataAndSetting={{
                    data: {
                      names: yAxisData.length > 0 ? yAxisData : [t('NO_RESULT')],
                      values: xAxisData.length > 0 ? xAxisData : [0],
                    },
                    height: 300,
                    width: "100%",
                    showDataZoom: false,
                    xAxisLabelRotate: 0,
                    barMaxWidth: 30,
                    yMax: 100,
                    secondBar: schoolXAxisData.length > 0 ? schoolXAxisData : [0],
                  }}
                />

                <table width="100%">
                  <thead className="bg-barPrimary text-white text-center text-sm">
                    <td className="border-r p-2">{t('QUESTION_NO')}</td>
                    <td className="border-r p-2">Strand</td>
                    <td className="border-r p-2">{t('PERCENTAGE_CORRECT')}</td>
                    <td className="border-r p-2">{t('QUESTION_DIFFICULTY')}</td>
                    <td className="border-r p-2">{t('CORRECT_ANSWER')}</td>
                    <td className="border-r p-2">{t('STUDENT_ANSWER')}</td>
                  </thead>
                  <tbody className="text-center">
                    {
                      activedDetail.response.length > 0 &&
                      activedDetail.response.map((response, index) => (
                        <tr key={`pat_response_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                          <td className="p-2">{response.questionNumber}</td>
                          <td className="p-2">{response.strand}</td>
                          <td className="p-2">{response.correctPercentage}</td>
                          <td className="p-2">{response.difficulty}</td>
                          <td className={`p-2 ${response.correctAnswer != response.studentAnswer && 'text-red-500 font-bold'}`}>
                            {response.correctAnswer}
                          </td>
                          <td className={`p-2 ${response.correctAnswer != response.studentAnswer && 'text-red-500 font-bold'}`}>
                            {response.studentAnswer}
                          </td>
                        </tr>
                      ))

                    }
                  </tbody>
                </table>
              </>
            }
          </>
      }
    </>
  )
})

export default AcademicPAT;