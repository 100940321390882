import React, { useState, useEffect } from 'react';
import ReactECharts from "echarts-for-react";
import useStores from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import LoadingPanel from '../loading-panel';
import { CircularProgress } from '@material-ui/core';
import IESLine from '../charts/iesLine';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import VMCard from '../collapsed-card';

const DISPLAY_METHOD = {
    'byWeek': 0,
    'byMonth': 1,
    'byYear': 2,
    'late': 1,
    'attendance': 0,
}

const WellBeingTab = observer(({ date }) => {
    const { t } = useTranslation();
    const { studentStore, filterStore } = useStores();
    const [selectedDate, setSelectedDate] = useState();
    const [isLineClicked, setIsLineClicked] = useState(false);
    const [attendence, setAttendance] = useState<any>();
    const [attendanceDisplayMethod, setAttendanceDisplayMethod] = useState(DISPLAY_METHOD.byMonth);
    const [lateDisplayMethod, setLateDisplayMethod] = useState(DISPLAY_METHOD.byMonth);

    useEffect(() => {
        if (studentStore.attendanceData && Object.keys(studentStore.attendanceData).length > 0) {
            setAttendance(studentStore.attendanceData);
        }
    }, [studentStore.attendanceData])

    const AttendanceOption = {
        grid: {
            left: 80,
            right: 60,
        },
        title: {
            left: '10%',
            textStyle: {
                fontFamily: 'Arial',
                fontSize: 24,
                color: 'black',
                fontWeight: 'normal',
            },
        },
        legend: {},
        tooltip: { trigger: "axis" },
        xAxis: {
            type: 'category',
            data: attendence ?
                attendanceDisplayMethod == DISPLAY_METHOD.byMonth ? attendence.monthlyData[0].detailData.map(detailD => detailD.label)
                    : attendence.weeklyData[0].detailData.map(detailD => detailD.label) : [],
            axisLabel: {
                interval: 1,
                rotate: 30, //If the label names are too long you can manage this by rotating the label.
                fontWeight: 'normal',
                fontStyle: 'italic',
                fontSize: 15,
                fontFamily: 'monospace',
                color: '#171717'
            }
        },
        yAxis: {
            name: 'Attendance Rate (%)',
            nameLocation: 'middle',
            nameGap: 40,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dashed",
                    width: 1.2,
                },
            },
        },
        series: [{
            data: attendence ?
                attendanceDisplayMethod == DISPLAY_METHOD.byMonth ? attendence.monthlyData[0].detailData.map(detailD => detailD.amount)
                    : attendence.weeklyData[0].detailData.map(detailD => detailD.amount) : [],
            type: 'line',
            lineStyle: { width: 1 },
            symbolSize: 10,

        }]
    }

    const lateOption = {
        grid: {
            left: 80,
            right: 60,

        },
        title: {
            left: '2%',
            textStyle: {
                fontFamily: 'Arial',
                fontSize: 24,
                color: 'black',
                fontWeight: 'normal'
            },
        },

        legend: {},
        tooltip: { trigger: "axis" },
        xAxis: {
            type: 'category',
            data: attendence ?
                lateDisplayMethod == DISPLAY_METHOD.byMonth ? attendence.monthlyData[1].detailData.map(detailD => detailD.label)
                    : attendence.weeklyData[1].detailData.map(detailD => detailD.label) : [],
            axisLabel: {
                interval: 1,
                rotate: 30,
                fontWeight: 'normal',
                fontStyle: 'italic',
                fontSize: 15,
                fontFamily: 'monospace',
                color: 'black'
            }
        },
        yAxis: {
            name: 'Number to Late-to-Class',
            type: 'value',
            nameLocation: 'middle',
            nameGap: 40,
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dashed",
                    width: 1.2,
                },
            },
        },
        series: [{
            data: attendence ?
                lateDisplayMethod == DISPLAY_METHOD.byMonth ? attendence.monthlyData[1].detailData.map(detailD => detailD.amount)
                    : attendence.weeklyData[1].detailData.map(detailD => detailD.amount) : [],
            type: 'line',
            lineStyle: {
                width: 1.2,
            },
            symbolSize: 10,
        },
        ]
    };

    const onEvents = {
        click: function (params) {
            setSelectedDate(params.name);
            setIsLineClicked(true);
        },
    };
    return (
        <VMCard titleClassName='my-4' className='mt-4'>

            <div className="text-barPrimary text-xl text-center mt-8">{t('OVERALL_ATTENDANCE_RATE')} (%) <b>({date})</b></div>
            <div className="flex justify-end mx-4">
                <button
                    type="button"
                    className={`mr-4 p-2 rounded-lg text-white ${attendanceDisplayMethod == DISPLAY_METHOD.byMonth ? 'bg-barPrimary' : 'bg-gray-400'}`}
                    onClick={() => setAttendanceDisplayMethod(DISPLAY_METHOD.byMonth)}
                >
                    {t('BY_MONTH')}
                </button>
                <button
                    type="button"
                    className={`p-2 rounded-lg text-white ${attendanceDisplayMethod == DISPLAY_METHOD.byWeek ? 'bg-barPrimary' : 'bg-gray-400'}`}
                    onClick={() => setAttendanceDisplayMethod(DISPLAY_METHOD.byWeek)}
                >
                    {t('BY_WEEK')}
                </button>
            </div>
            {
                studentStore.loading || !attendence ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                    :
                    <ReactECharts
                        style={{ height: "300px", width: "100%" }}
                        option={AttendanceOption}
                        onEvents={onEvents}
                    />
            }
            <div className="text-barPrimary text-xl text-center mt-8">{t('HOW_MANY_TIME_LATE_TO_CLASS')} <b>({date})</b></div>
            <div className="flex justify-end mx-4">
                <button
                    type="button"
                    className={`mr-4 p-2 rounded-lg text-white ${lateDisplayMethod == DISPLAY_METHOD.byMonth ? 'bg-barPrimary' : 'bg-gray-400'}`}
                    onClick={() => setLateDisplayMethod(DISPLAY_METHOD.byMonth)}
                >
                    {t('BY_MONTH')}
                </button>
                <button
                    type="button"
                    className={`p-2 rounded-lg text-white ${lateDisplayMethod == DISPLAY_METHOD.byWeek ? 'bg-barPrimary' : 'bg-gray-400'}`}
                    onClick={() => setLateDisplayMethod(DISPLAY_METHOD.byWeek)}
                >
                    {t('BY_WEEK')}
                </button>
            </div>
            {studentStore.loading || !attendence ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <ReactECharts
                    style={{ height: "300px", width: "100%" }}
                    option={lateOption}
                    onEvents={onEvents}
                />}
            {/* {isLineClicked && <div>

                    <table className="border-2 text-left ml-10 mt-14 w-3/4 ">
                        <thead>
                            <tr className=" bg-gray-100 ">
                                <th className="border-2  p-3"> Absences</th>

                            </tr>
                        </thead>
                        <tbody className="border-2">
                            {attendence
                                .filter((attendence) => attendence.detailData.label === selectedDate)
                                .map((attendence) =>
                                    attendence.Absense.map((absence) => (
                                        <tr>
                                            <td className="border-2  p-3 ">
                                                {absence}
                                            </td>
                                        </tr>
                                    ))
                                )}
                        </tbody>
                    </table>

                </div>} */}
        </VMCard >
    );
})

export default WellBeingTab;