import React, { useState, useRef, useEffect } from "react";
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';
import { CanvasService } from './canvas-service';
import { CircularProgress, Tab, Tabs } from "@material-ui/core";
import IESBoxPlot from "../charts/iesBoxplot";
import { CHART_WIDTH, BAR_RADIUS, THEME_CLASS } from "../../constants/style";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import TabPanel from "../tab-panel";
import IESRadar from "../charts/iesRadar";
import VMCard from "../collapsed-card";
import IESMultiBarChart from "../charts/iesMultiBarChart";

function TabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const AcademicExternalNaplan = observer(({ stdUid }) => {
  const { studentStore } = useStores();
  const [performanceData, setPerformanceData] = useState<any>({});
  const [secPerformanceData, setSecPerformanceData] = useState<any>({});
  const [tabValue, setTabValue] = useState<number>(0);
  const [activeStrand, setActiveStrand] = useState<string>("");
  const [minNumSeven, setMinNumSeven] = useState<number>(1000);
  const [minNumNine, setMinNumNine] = useState<number>(1000);
  const [naplanXAxis, setNaplanXAxis] = useState<any>([]);
  const [naplanSeriesData, setNaplanSeriesData] = useState<any>([]);
  const myRef = useRef(null);
  const canvasRef1 = useRef(null);
  const { t } = useTranslation();
  const onChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
    studentStore.naplanDetailData = [];
  }

  // draw background, indicators and subject names
  const drawBackground = (context, data) => {
    var canvasService = new CanvasService();
    const [canvas, ctx] = canvasService.getContext(context);
    const padding = 20;
    const backGradient = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
    backGradient.addColorStop("0", "#8cc8ed");
    backGradient.addColorStop("0.3", "white");
    backGradient.addColorStop("1.0", "#fafafa");
    canvasService.fillRect(0, 0, canvas.width, canvas.height, backGradient);
    canvasService.fillRect(padding * 3, padding, 400, 35, 'white');
    canvasService.text('KEY', padding * 3 + 10, padding + 23, '#0f2080', 'bold 15px Arial');
    canvasService.drawCircle(padding * 3 + 70, padding + 17.5, 6, 'black', 'fill');
    canvasService.text('Student score', padding * 3 + 80, padding + 23, 'black', '12px Arial');
    const filledIndicatorPos =
      [[padding * 3 + 180, padding + 17.5], [padding * 3 + 180 + 8, padding + 17.5 - 4], [padding * 3 + 180 + 8, padding + 17.5 + 4]];
    canvasService.drawPolygon(filledIndicatorPos, 'black', 'fill');
    canvasService.text('National Avg.', padding * 3 + 192, padding + 23, 'black', '12px Arial');
    const strokeIndicatorPos =
      [[padding * 3 + 280, padding + 17.5], [padding * 3 + 280 - 8, padding + 17.5 - 4], [padding * 3 + 280 - 8, padding + 17.5 + 4]];
    canvasService.drawPolygon(strokeIndicatorPos, 'black', 'stroke');
    canvasService.text('School Avg.', padding * 3 + 283, padding + 23, 'black', '12px Arial');

    const sectionWidth = canvas.width / data.length;
    for (let i = 0; i < data.length; i++) {
      const subject = data[i];
      ctx.font = 'bold 14px Arial';
      const titleDimension = ctx.measureText(subject.strandName);
      const titleWidth = titleDimension.width;
      const titlePos = [sectionWidth / 2 + sectionWidth * i - titleWidth / 2, padding * 4];
      canvasService.text(subject.strandName, titlePos[0], titlePos[1], '#123369', 'bold 14px Arial');
    }
  }

  // draw bands and year description
  const drawData = (context, data, leftPadding, yearDescription) => {
    var canvasService = new CanvasService();
    const [canvas, ctx] = canvasService.getContext(context);
    const length = data.length;
    const padding = 20;
    const sectionWidth = (canvas.width - padding * 4) / length + 10;

    for (let i = 0; i < length; i++) {
      const subject = data[i];
      const bandWidth = 30;
      const fullMark = 1000;
      const topLeft = [leftPadding + i * sectionWidth, padding * 6];
      let previousHeight = 0;
      let previousScore = 1000;
      subject.bands.reverse();
      for (const [idx, band] of subject.bands.entries()) {
        const bandPos = [topLeft[0] + padding, topLeft[1] + previousHeight];
        if (idx == subject.bands.length - 1) {
          band.score = 0;
        }
        const bandHeight = (canvas.height - padding * 8) * ((previousScore - band.score) / 1000);
        previousHeight += bandHeight;
        previousScore = band.score;
        const gradient = ctx.createLinearGradient(
          bandPos[0],
          bandPos[1] + bandHeight,
          bandPos[0] + bandWidth,
          bandPos[1] + bandHeight);
        gradient.addColorStop("0", "#ebab34");
        gradient.addColorStop("0.3", "#ebab34");
        gradient.addColorStop("1.0", "#edcb8a");
        canvasService.fillRect(bandPos[0], bandPos[1], bandWidth, bandHeight, gradient);
        canvasService.strokeRect(bandPos[0], bandPos[1], bandWidth, bandHeight, 'black');
        ctx.font = '12px Arial'; //bold
        const textDimension = ctx.measureText("" + band.band); //Band
        const textWidth = textDimension.width;
        const textHeight = 6;
        const textPos = [(bandPos[0] + bandWidth / 2) - (textWidth / 2), (bandPos[1] + bandHeight / 2) + (textHeight)];
        canvasService.text("" + band.band, textPos[0], textPos[1], '#123369', '12px Arial'); //Band
      }
      const outerBarPos = [topLeft[0] + padding + bandWidth, topLeft[1]];
      const outerBarWidth = 12;
      const outerBarHeight = (canvas.height - padding * 8);
      canvasService.fillRect(outerBarPos[0], outerBarPos[1], outerBarWidth, outerBarHeight, '#5380bd');
      canvasService.strokeRect(outerBarPos[0], outerBarPos[1], outerBarWidth, outerBarHeight, 'black');
      const topRatio = 1 - (subject.schoolHighest / fullMark);
      const innerBarPos = [outerBarPos[0], outerBarPos[1] + (outerBarHeight * topRatio)];
      const innerBarWidth = 12;
      const innerBarHeight = outerBarHeight * (subject.schoolHighest - subject.schoolLowest) / fullMark;
      canvasService.fillRect(innerBarPos[0], innerBarPos[1], innerBarWidth, innerBarHeight, '#c9ced4');

      const indicator1 = subject.schoolAverage;
      const indicator2 = subject.nationalAverage;
      const idc1Begin = [outerBarPos[0], outerBarPos[1] + (1 - indicator1 / fullMark) * outerBarHeight];
      const idc1Pos = [idc1Begin, [idc1Begin[0] - 8, idc1Begin[1] - 4], [idc1Begin[0] - 8, idc1Begin[1] + 4]];
      canvasService.drawPolygon(idc1Pos, 'black', "stroke");

      const idc2Begin = [outerBarPos[0] + outerBarWidth, outerBarPos[1] + (1 - indicator2 / fullMark) * outerBarHeight];
      const idc2Pos = [idc2Begin, [idc2Begin[0] + 8, idc2Begin[1] - 4], [idc2Begin[0] + 8, idc2Begin[1] + 4]];
      canvasService.drawPolygon(idc2Pos, 'black', "fill");

      const circleCenter = [outerBarPos[0] + outerBarWidth / 2, outerBarPos[1] + (1 - subject.studentResult / fullMark) * outerBarHeight];
      canvasService.drawCircle(circleCenter[0], circleCenter[1], 5, 'black', 'fill');
      /*
      ctx.font = 'bold 14px Arial';
      const titleDimension = ctx.measureText(subject.strandName);
      const titleWidth = titleDimension.width;
      const titlePos = [topLeft[0] + padding + outerBarWidth / 2 + bandWidth / 2 - titleWidth / 2, padding * 4];
      canvasService.text(subject.strandName, titlePos[0], titlePos[1], '#123369', 'bold 14px Arial');
      */
      const yearPos = [topLeft[0] + padding + outerBarWidth / 2, padding * 5];
      canvasService.text(yearDescription, yearPos[0], yearPos[1], '#123369', 'bold 14px Arial');
    }
  }

  useEffect(() => {
    studentStore.getNaplanData(stdUid).then((res) => {
      const canvas1 = canvasRef1.current;
      if (canvas1) {
        const context1 = canvas1.getContext('2d');
        if (res.length == 1) {
          drawBackground(context1, res[0].data);
          drawData(context1, res[0].data, 2, '' + studentStore.naplanData[0].year + ' (Y' + studentStore.naplanData[0].yearLevel + ')');
        }
        else if (res.length == 2) {
          drawBackground(context1, res[0].data);
          drawData(context1, res[0].data, 1, '' + studentStore.naplanData[0].year + ' (Y' + studentStore.naplanData[0].yearLevel + ')');
          drawData(context1, res[1].data, 87, '' + studentStore.naplanData[1].year + ' (Y' + studentStore.naplanData[1].yearLevel + ')');
        }
      }
      if (studentStore.naplanData.length > 0) {
        // Overview
        let xAxis = res[0].data.map(n => n.strandName);
        setNaplanXAxis(xAxis);
        setNaplanSeriesData([
          {
            name: res[0].year + " Y" + res[0].yearLevel,
            type: "bar",
            data: res[0].data.map(n => n.studentResultBand),
            label: { show: true, position: "top" },
            itemStyle: {
              // barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0],
              color: THEME_CLASS[0].chartColorLibrary[0],
            },
          },
          res.length > 1 && {
            name: res[1].year + " Y" + res[1].yearLevel,
            type: "bar",
            data: res[1].data.map(n => n.studentResultBand),
            label: { show: true, position: "top" },
            itemStyle: {
              // barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0],
              color: THEME_CLASS[0].chartColorLibrary[1],
            }
          }
        ]);

        // Tab 2
        let tempCat = studentStore.naplanData[0].data.map((data) => data.strandName);
        let index = tempCat.indexOf("Grammar & Punctuation");
        tempCat[index] = "G&P";
        let tempScoreList = [];
        let tempStudentScore = [];
        let tempMinNum = 1000;
        for (var score of studentStore.naplanData[0].data) {
          tempScoreList.push(score.rawScoreList);
          tempStudentScore.push(score.studentResult);
          let min = Math.min.apply(Math, score.rawScoreList);
          if (min < tempMinNum) tempMinNum = min;
        }
        setMinNumSeven(tempMinNum - 10);
        setPerformanceData({
          boxData: tempScoreList,
          category: tempCat,
          studentScore: tempStudentScore,
          markComparisionStateData: studentStore.naplanData[0].data.map((data) => data.stateAverage),
          markComparisionNationalData: studentStore.naplanData[0].data.map((data) => data.nationalAverage ? data.nationalAverage : 0),
          title: `NAPLAN ${t('PERFORMANCE')}: ${studentStore.naplanData[0].year} YL${studentStore.naplanData[0].yearLevel}`
        });

        // If the student done two naplans already
        if (studentStore.naplanData.length > 1) {
          let tempCat = studentStore.naplanData[1].data.map((data) => data.strandName);
          let index = tempCat.indexOf("Grammar & Punctuation");
          tempCat[index] = "G&P";
          let tempScoreList = [];
          let tempStudentScore = [];
          let tempMinNum = 1000;
          for (var score of studentStore.naplanData[1].data) {
            tempScoreList.push(score.rawScoreList);
            tempStudentScore.push(score.studentResult);
            let min = Math.min.apply(Math, score.rawScoreList);
            if (min < tempMinNum) tempMinNum = min;
          }
          setMinNumNine(tempMinNum - 10);
          setSecPerformanceData({
            boxData: tempScoreList,
            category: tempCat,
            studentScore: tempStudentScore,
            markComparisionStateData: studentStore.naplanData[0].data.map((data) => data.stateAverage),
            markComparisionNationalData: studentStore.naplanData[0].data.map((data) => data.nationalAverage ? data.nationalAverage : 0),
            title: `NAPLAN ${t('PERFORMANCE')}: ${studentStore.naplanData[1].year} YL${studentStore.naplanData[1].yearLevel}`
          });
        }
      }

    });
  }, [stdUid])

  const onClickChartMultiBar = {
    click: function (params) {
      console.log(params);
      var resultId = 0;
      var strandId = 0;
      if (params.seriesName.includes("Y7")) {
        onChangeTabValue(0, 1);
        resultId = studentStore.naplanData[0].studentResultId.toString();
        strandId = params.name === "G&P" ? studentStore.naplanData[tabValue - 1].data
          .find(data => data.strandName === "Grammar & Punctuation").strandId
          : studentStore.naplanData[0].data
            .find(data => data.strandName === params.name).strandId;
      } else {
        onChangeTabValue(0, 2);
        resultId = studentStore.naplanData[1].studentResultId.toString();
        strandId = params.name === "G&P" ? studentStore.naplanData[tabValue - 1].data
          .find(data => data.strandName === "Grammar & Punctuation").strandId
          : studentStore.naplanData[1].data
            .find(data => data.strandName === params.name).strandId;
      }
      setActiveStrand(params.name === "G&P" ? "Grammar & Punctuation" : params.name);
      studentStore.getStudentNaplanResponses(resultId, strandId);
      window.scroll({
        top: myRef.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    },
  };

  const onClickChartBar = {
    click: function (params) {
      setActiveStrand(params.name === "G&P" ? "Grammar & Punctuation" : params.name);
      var resultId = studentStore.naplanData[tabValue - 1].studentResultId.toString();
      var strandId = params.name === "G&P" ? studentStore.naplanData[tabValue - 1].data
        .find(data => data.strandName === "Grammar & Punctuation").strandId
        : studentStore.naplanData[tabValue - 1].data
          .find(data => data.strandName === params.name).strandId;

      studentStore.getStudentNaplanResponses(resultId, strandId);

      window.scroll({
        top: myRef.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    },
  };

  return (
    <div>
      {studentStore.naplanLoading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
        :
        studentStore.naplanData.length > 0 ?
          <VMCard
            titleComponent={
              <Tabs
                value={tabValue}
                onChange={onChangeTabValue}
                TabIndicatorProps={{ style: { display: "none" } }}
              >
                <Tab label={t('YEARLY_PERFORMANCE')} {...TabProps(0, 0 === tabValue)} />
                {studentStore.naplanData.length > 0 && <Tab label={'NAPLAN YL7'} {...TabProps(1, 1 === tabValue)} />}
                {studentStore.naplanData.length > 1 && <Tab label={'NAPLAN YL9'} {...TabProps(2, 2 === tabValue)} />}
              </Tabs>
            }
          >
            <TabPanel value={tabValue} index={0}>
              <div className="text-barPrimary text-xl text-center my-8">{t('STUDENT_NAPLAN_YEARLY_PERFORMANCE_OVERVIEW')}</div>
              {
                studentStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <IESMultiBarChart
                    setting={{
                      height: 300,
                      width: '100%',
                      xAxis: naplanXAxis,
                      series: naplanSeriesData
                    }}
                    onEvents={onClickChartMultiBar}
                  />
              }
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <div className="text-center mb-4 text-barPrimary text-xl mb-8 mt-4">{performanceData.title ? performanceData.title : 'NAPLAN'}</div>
              <IESBoxPlot
                dataAndSetting={{
                  data: performanceData.boxData ? performanceData : { boxData: [[0]], category: [t('NO_RESULT')] },
                  height: 400,
                  width: CHART_WIDTH,
                  xAxisLabelRotate: 45,
                  markComparisionData: performanceData && performanceData.studentScore,
                  markComparisionStateData: performanceData && performanceData.markComparisionStateData,
                  markComparisionNationalData: performanceData && performanceData.markComparisionNationalData,
                  temp: 1,
                  min: minNumSeven
                }}
                callback={onClickChartBar}
              />
              <div ref={myRef} className="mt-4" />
              {
                studentStore.loadingNaplanDetail ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  : studentStore.naplanDetailData.length > 0 &&
                  <VMCard
                    className="px-4"
                    titleComponent={<div className="mb-4 ml-4 text-barPrimary text-xl my-4">{activeStrand}</div>}
                  >
                    <div className="max-h-96 overflow-auto">
                      <table width="100%">
                        <thead className="bg-barPrimary text-white text-center text-sm">
                          <td className="border-r p-2">{t('QUESTION_NO')}</td>
                          <td className="border-r p-2">Strand</td>
                          <td className="border-r p-2">{t('PERCENTAGE_CORRECT')}</td>
                          <td className="border-r p-2">{t('CORRECT_ANSWER')}</td>
                          <td className="border-r p-2">{t('STUDENT_ANSWER')}</td>
                        </thead>
                        <tbody className="text-center text-sm">
                          {studentStore.naplanDetailData
                            .map((response, index) => (
                              <tr key={`yl7_naplan_question_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                                <td className="p-2">{response.questionNumber}</td>
                                <td className="p-2">{response.strand}</td>
                                <td className="p-2">{response.schoolCorrectPercentage}</td>
                                <td className={`p-2 ${response.correctAnswer != response.studentAnswer && 'text-red-500 font-bold'}`}>
                                  {response.correctAnswer}
                                </td>
                                <td className={`p-2 ${response.correctAnswer != response.studentAnswer && 'text-red-500 font-bold'}`}>
                                  {response.studentAnswer}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </VMCard>
              }

            </TabPanel>

            {
              studentStore.naplanData.length > 1 &&
              <>
                <TabPanel value={tabValue} index={2}>
                  <div className="text-center mb-4 text-barPrimary text-xl mt-4 mb-8">{secPerformanceData.title ? secPerformanceData.title : 'NAPLAN'}</div>
                  <IESBoxPlot
                    dataAndSetting={{
                      data: secPerformanceData.boxData ? secPerformanceData : { boxData: [[0]], category: [t('NO_RESULT')] },
                      height: 400,
                      width: CHART_WIDTH,
                      xAxisLabelRotate: 45,
                      markComparisionData: secPerformanceData && secPerformanceData.studentScore,
                      markComparisionStateData: secPerformanceData && secPerformanceData.markComparisionStateData,
                      markComparisionNationalData: secPerformanceData && secPerformanceData.markComparisionNationalData,
                      min: minNumNine
                    }}
                    callback={onClickChartBar}
                  />

                  <div ref={myRef} className="mt-4" />
                  {
                    studentStore.loadingNaplanDetail ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                      : studentStore.naplanDetailData.length > 0 &&
                      <VMCard
                        className="px-4"
                        titleComponent={<div className="mb-4 ml-4 text-barPrimary text-xl my-4">{activeStrand}</div>}
                      >

                        <div className="max-h-96 overflow-auto">
                          <table width="100%">
                            <thead className="bg-barPrimary text-white text-center text-sm">
                              <td className="border-r p-2">{t('QUESTION_NO')}</td>
                              <td className="border-r p-2">Strand</td>
                              <td className="border-r p-2">{t('PERCENTAGE_CORRECT')}</td>
                              <td className="border-r p-2">{t('CORRECT_ANSWER')}</td>
                              <td className="border-r p-2">{t('STUDENT_ANSWER')}</td>
                            </thead>
                            <tbody className="text-center text-sm">
                              {
                                studentStore.naplanDetailData
                                  .map((response, index) => (
                                    <tr key={`yl7_naplan_question_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                                      <td className="p-2">{response.questionNumber}</td>
                                      <td className="p-2">{response.strand}</td>
                                      <td className="p-2">{response.schoolCorrectPercentage}</td>
                                      <td className={`p-2 ${response.correctAnswer != response.studentAnswer && 'text-red-500 font-bold'}`}>
                                        {response.correctAnswer}
                                      </td>
                                      <td className={`p-2 ${response.correctAnswer != response.studentAnswer && 'text-red-500 font-bold'}`}>
                                        {response.studentAnswer}
                                      </td>
                                    </tr>
                                  ))

                              }
                            </tbody>
                          </table>
                        </div>
                      </VMCard>
                  }
                </TabPanel>
              </>
            }

          </VMCard>
          : <div>No Result</div>
      }
    </div>
  )
});

export default AcademicExternalNaplan;