import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';

const IESLineAreaChart = observer(({ setting }) => {
  var option = {
    tooltip: {
      trigger: 'axis',
      position: function (pt) {
        return [pt[0], '10%'];
      },
      formatter: function(params) {
        return `<div style='height:10;width:10;background-color:${params[0].color}'></div> <b>` + params[0].axisValue + "</b> Growth Rate: " + params[0].data + "%";
      }
    },
    grid: {
      top: '10%',
      left: '3%',
      right: '5%',
      bottom: '7%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: setting.xAxis,
    },
    yAxis: {
      type: 'value',
      name: '%',
      boundaryGap: [0, '100%'],
      max: setting.max ? setting.max : 20,
      min: setting.min ? setting.min : -20,
    },
    series: [
      {
        type: 'line',
        smooth: true,
        data: setting.seriesData,
        areaStyle: {}
      },
    ],
    visualMap: {
      left: 'right',
      min: 0,
      max: 1,
      inRange: {
        // color: ['#74a5f7', '#55cfe4'],
        color: ['#ec5050', '#0a9d0a'],
      },
      text: ['>0', '<0'],
      calculable: true,
      show: false
    },
  }

  return (
    <>
      <ReactEcharts
        style={{
          height: setting.height,
          width: setting.width,
          margin: '0 auto',
        }}
        option={option}
      />
    </>
  );
});

export default IESLineAreaChart;